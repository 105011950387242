import { Box, Divider, Typography } from "@mui/material";
import { useEffect, useRef } from "react";
import { CloudToSheetAlignmentStepNames } from "../alignment-steps/steps";

// Import animation files "?url" tells webpack to just put the file as static content
// and return the url in the imported variable
import {
  HelpAnimation,
  HelpDrawer,
  HelpHeader,
  HelpParagraph,
} from "@/components/ui/quick-help-components";
import { QuickHelpHeader } from "@/components/ui/quick-help-header";
import { StaticResource, getStaticResourceUrl } from "@/utils/static-resources";

export type QuickHelpProps = {
  /** Function to call when user requests to close the drawer */
  onClose(): void;

  /** Id of the active step */
  activeStepId: number;

  /** Currently active alignment step, used for deciding which content to who */
  activeStep: CloudToSheetAlignmentStepNames;

  /** True if the user has currently enabled two point alignment */
  isTwoPointAlignmentActive: boolean;
};

export type QuickHelpPageProps = {
  /** Id of the active step */
  stepNumber: number;
};

/**
 * @returns A drawer showing help for the current alignment step
 */
export function QuickHelp({
  onClose,
  activeStepId,
  activeStep,
  isTwoPointAlignmentActive,
}: QuickHelpProps): JSX.Element {
  const scrollableAreaRef = useRef<HTMLDivElement>(null);

  // Scroll back to top after step change
  useEffect(() => {
    scrollableAreaRef.current?.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, [activeStep, scrollableAreaRef]);

  let content: JSX.Element;
  switch (activeStep) {
    case CloudToSheetAlignmentStepNames.alignIn2d:
      content = isTwoPointAlignmentActive ? (
        <TwoPointsAlignHelp stepNumber={activeStepId + 1} />
      ) : (
        <OnePointAlignHelp stepNumber={activeStepId + 1} />
      );
      break;
    case CloudToSheetAlignmentStepNames.setElevation:
      content = <HeightHelp stepNumber={activeStepId + 1} />;
      break;
  }

  return (
    <HelpDrawer>
      <QuickHelpHeader title="Quick Help" onClose={onClose} />

      <Box
        ref={scrollableAreaRef}
        component="div"
        sx={{
          overflow: "auto",
          ml: 2,
          pr: 2,
          mt: 2,
          height: "100%",
        }}
      >
        {content}
      </Box>
    </HelpDrawer>
  );
}

function OnePointAlignHelp({ stepNumber }: QuickHelpPageProps): JSX.Element {
  return (
    <>
      <HelpHeader stepNumber={stepNumber}>Align</HelpHeader>

      <HelpParagraph header="One point alignment">
        A scale has been already defined for this sheet. Unless you want to
        change it, you can only translate and rotate the point cloud.
      </HelpParagraph>

      <HelpParagraph>
        The point cloud coordinate system and its geolocation will remain
        unchanged.
      </HelpParagraph>

      <Divider sx={{ bgcolor: "white", opacity: 0.3 }} />

      <HelpParagraph header="1 of 2">
        <Typography sx={{ fontSize: "0.875rem" }}>
          Left-click to insert the first anchor point.
          <br />
          <b>Translate</b> the point cloud by dragging the anchor point or press
          SHIFT+Left-click on the point cloud.
          <br />
          <b>Rotate</b> the point cloud by clicking and dragging.
        </Typography>
        <HelpAnimation
          src={getStaticResourceUrl(StaticResource.help2FirstPinAnimation)}
        />
      </HelpParagraph>

      <HelpParagraph header="2 of 2">
        Click on the Next button to go to the last step
      </HelpParagraph>

      <Divider sx={{ bgcolor: "white", opacity: 0.3 }} />

      <HelpParagraph header="Change reference scale.">
        Set a new scale for the project by clicking on the “Reset Scale” button
        available in the toolbar.
      </HelpParagraph>

      <HelpParagraph header="Recenter the point cloud on the sheet.">
        Re-center the position of the point cloud on the sheet by clicking on
        the “Re-center point cloud on sheet” button
      </HelpParagraph>

      <HelpParagraph header="Remove anchor point.">
        Right-click to remove the anchor point.
      </HelpParagraph>
    </>
  );
}

function TwoPointsAlignHelp({ stepNumber }: QuickHelpPageProps): JSX.Element {
  return (
    <>
      <HelpHeader stepNumber={stepNumber}>Align</HelpHeader>

      <HelpParagraph header="Two points alignment">
        Insert two anchor points to rotate and scale the point cloud.
      </HelpParagraph>

      <HelpParagraph>
        The point cloud coordinate system and its geolocation will remain
        unchanged.
      </HelpParagraph>

      <Divider sx={{ bgcolor: "white", opacity: 0.3 }} />

      <HelpParagraph header="1 of 3">
        <Typography sx={{ fontSize: "0.875rem" }}>
          Left-click to insert the first anchor point.
          <br />
          <b>Translate</b> the point cloud by dragging the anchor point or press
          SHIFT+Left-click on the point cloud.
          <br />
          <b>Rotate</b> the point cloud by clicking and dragging.
        </Typography>
        <HelpAnimation
          src={getStaticResourceUrl(StaticResource.help2FirstPinAnimation)}
        />
      </HelpParagraph>

      <HelpParagraph header="2 of 3">
        Left-click to insert a second anchor point to scale, translate and
        rotate the point cloud.
        <HelpAnimation
          src={getStaticResourceUrl(StaticResource.help2SecondPinAnimation)}
        />
      </HelpParagraph>

      <HelpParagraph header="3 of 3">
        Click on the Next button to go to the last step data.
      </HelpParagraph>

      <Divider sx={{ bgcolor: "white", opacity: 0.3 }} />

      <HelpParagraph header="Recenter the point cloud on the sheet.">
        Re-center the position of the point cloud on the sheet by clicking on
        the “Re-center point cloud on sheet” button
      </HelpParagraph>

      <HelpParagraph header="Remove anchor point.">
        Right-click to remove the anchor point.
      </HelpParagraph>
    </>
  );
}

function HeightHelp({ stepNumber }: QuickHelpPageProps): JSX.Element {
  return (
    <>
      <HelpHeader stepNumber={stepNumber}>Elevation</HelpHeader>

      <HelpParagraph>
        Click on the ground in the point cloud to automatically match the height
        of the floor plan.
        <HelpAnimation
          src={getStaticResourceUrl(StaticResource.help3GroundClickAnimation)}
        />
      </HelpParagraph>

      <Divider
        sx={{
          "&::before, &::after": {
            borderColor: "white",
            opacity: 0.3,
          },
        }}
      >
        or
      </Divider>

      <HelpParagraph>
        You can move the point cloud up and down with the arrows.
        <HelpAnimation
          src={getStaticResourceUrl(StaticResource.help3MoveArrowsAnimation)}
        />
      </HelpParagraph>
    </>
  );
}
