import {
  TREE_NODE_HEIGHT,
  TreeNode,
  TreeNodeProps,
} from "@/components/ui/tree/tree-node";
import { TreeWrapper } from "@/components/ui/tree/tree-wrapper";
import { RootState } from "@/store/store";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { FaroTooltip, ScanIcon, TruncatedFaroText } from "@faro-lotv/flat-ui";
import { GUID } from "@faro-lotv/foundation";
import {
  RevisionScanEntity,
  isRevisionScanEntity,
} from "@faro-lotv/service-wires";
import { Stack } from "@mui/system";
import { useEffect } from "react";
import { Tree } from "react-arborist";
import { selectSelectedEntityId } from "../store/data-preparation-ui/data-preparation-ui-selectors";
import {
  setHoveredEntityId,
  setSelectedEntityId,
  unsetHoveredEntityId,
} from "../store/data-preparation-ui/data-preparation-ui-slice";
import {
  selectPointCloudStreamForScanEntity,
  selectRevisionEntity,
} from "../store/revision-selectors";

type ScanListProps = {
  /** The scans to show in the list. */
  scanEntities: RevisionScanEntity[];
};

/** @returns A list of scans. */
export function ScanList({ scanEntities }: ScanListProps): JSX.Element {
  const dispatch = useAppDispatch();

  const selectedEntityId = useAppSelector(selectSelectedEntityId);

  // TODO: This tree view is only used to display a simple list for now with the correct styles.
  // Soon more layers in the tree will be implemented.
  // see https://faro01.atlassian.net/browse/NRT-1233
  return (
    <TreeWrapper>
      <Tree
        data={scanEntities}
        width="100%"
        disableDrag
        disableDrop
        rowHeight={TREE_NODE_HEIGHT}
        indent={2}
        disableMultiSelection
        selection={selectedEntityId}
        onSelect={(nodes) => {
          dispatch(setSelectedEntityId(nodes[0]?.data.id));
        }}
      >
        {ScanListNode}
      </Tree>
    </TreeWrapper>
  );
}

function ScanListNode({
  node,
}: TreeNodeProps<RevisionScanEntity>): JSX.Element {
  const dispatch = useAppDispatch();

  // Unset the global hover state if the component unmounts without a mouse event
  useEffect(() => {
    return () => {
      dispatch(unsetHoveredEntityId(node.id));
    };
  }, [dispatch, node.id]);

  const disabledReason = useAppSelector(selectScanNodeDisabledReason(node.id));
  const isDisabled = !!disabledReason;

  return (
    <FaroTooltip title={disabledReason} placement="right">
      <TreeNode<RevisionScanEntity>
        node={node}
        shouldExpandNodeOnClick={false}
        shouldDeselectOnClick
        onPointerEnter={() => {
          dispatch(setHoveredEntityId(node.id));
        }}
        onPointerLeave={() => {
          dispatch(unsetHoveredEntityId(node.id));
        }}
        isDisabled={isDisabled}
        isSelectable={!isDisabled}
      >
        <Stack direction="row" alignItems="center" gap={1} minWidth={0}>
          <ScanIcon sx={{ fontSize: "1.125rem" }} />
          <TruncatedFaroText variant="bodyM" color="inherit">
            {node.data.name}
          </TruncatedFaroText>
        </Stack>
      </TreeNode>
    </FaroTooltip>
  );
}

/**
 * @returns a reason for disabling a node in the scan tree, or undefined if the nodes should be enabled
 * @param nodeId the id of the entity for the node
 */
function selectScanNodeDisabledReason(nodeId: GUID) {
  return (state: RootState): string | undefined => {
    const nodeEntity = selectRevisionEntity(nodeId)(state);

    if (!nodeEntity) return;

    if (
      isRevisionScanEntity(nodeEntity) &&
      !selectPointCloudStreamForScanEntity(nodeEntity)(state)
    ) {
      return "This point cloud is currently not available";
    }
  };
}
