import { RootState } from "@/store/store";
import {
  IElement,
  isIElementGenericDataset,
  isIElementSectionDataSession,
} from "@faro-lotv/ielement-types";
import { isInsideCaptureTree, selectAncestor } from "@faro-lotv/project-source";
import { useMemo } from "react";

/**
 * Select the default area name based on the main element
 * If the main element is inside the capture tree, the default name is the dataset name
 * If the main element is inside the BI tree, the default name is the data session name
 *
 * @param main The main element in the scene
 * @returns The default area name
 */
export function selectNewAreaDefaultName(main: IElement) {
  return (appState: RootState): string | undefined => {
    // Check if the main element is inside the capture tree
    const fromCaptureTree = useMemo(
      () => isInsideCaptureTree(main, appState.iElements.iElements),
      [appState.iElements.iElements],
    );

    // Select the dataset or data session to get the default name from
    const dataSet = selectAncestor(
      main,
      (el) =>
        isIElementSectionDataSession(el) ||
        (fromCaptureTree && isIElementGenericDataset(el)),
    )(appState);

    return dataSet?.name;
  };
}
