import { AlignmentTransform } from "@/alignment-tool/store/alignment-slice";
import { alignmentTransformToMatrix4 } from "@/alignment-tool/utils/alignment-transform";
import { computeSplitScreenAlignment } from "@/alignment-tool/utils/compute-split-screen-alignment";
import { useActiveCameras } from "@/components/common/view-runtime-context";
import { SheetModeControls } from "@/components/r3f/controls/sheet-mode-controls";
import { TomographicOverlayViewPipeline } from "@/components/r3f/effects/tomographic-view-pipeline";
import { CadRenderer } from "@/components/r3f/renderers/cad-renderer";
import { SheetRenderer } from "@/components/r3f/renderers/sheet-renderer";
import {
  centerOrthoCamera,
  useCenterCameraOnPlaceholders,
} from "@/hooks/use-center-camera-on-placeholders";
import { useObjectVisibility } from "@/hooks/use-object-visibility";
import { useCached3DObject } from "@/object-cache";
import {
  selectAlignmentAnchorPositions,
  selectCrossSectionEnabledForTomographicView,
  selectIncrementalCadTransform,
  selectSheetElevation,
  selectSheetToCadAlignmentLayout,
} from "@/store/modes/sheet-to-cad-alignment-mode-selectors";
import {
  AlignmentViewLayout,
  setAreaToCadTransform,
  setMovingElementAnchor1,
  setMovingElementAnchor2,
  setReferenceElementAnchor1,
  setReferenceElementAnchor2,
} from "@/store/modes/sheet-to-cad-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  AnchorPairPlacement,
  ClearPass,
  CopyToScreenPass,
  DesaturatePass,
  EffectPipeline,
  FilteredRenderPass,
  Map2DControls,
  TomographicModelPass,
  TwoPointAlignment,
  UniformLights,
  View,
  quaternionToVector4Tuple,
  selectIElementWorldPosition,
  useNonExhaustiveEffect,
  useReproportionCamera,
} from "@faro-lotv/app-component-toolbox";
import {
  IElementGenericImgSheet,
  IElementImg360,
  IElementModel3dStream,
} from "@faro-lotv/ielement-types";
import { useThree } from "@react-three/fiber";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box3, Color, Quaternion, Vector3, Vector3Tuple } from "three";
import {
  useCadTomographicMaterial,
  useCameraInCurrentScene,
  useCenterCameraOnBoundingBoxIn2D,
  useCenterCameraOnCadModel,
  useClippingPlanesAtModelElevation,
  useNewOrthographicCamera,
  useSheetBoundingBox,
} from "../alignment-modes-commons/align-to-cad-utils";
import { useOverlayElements } from "../overlay-elements-context";
import { RenderDispatch } from "../overview-mode/render-dispatch";

export type AlignSheetToCadSceneProps = {
  /** The active sheet to be aligned to the CAD model */
  activeSheet: IElementGenericImgSheet;

  /** The active cad model for alignment */
  activeCad: IElementModel3dStream;

  /** The bounding box of the cad model in world */
  cadBox: Box3;
};

type AlignSheetToCadSplitScreenProps = {
  /** The active sheet to be aligned to the CAD model */
  activeSheet: IElementGenericImgSheet;

  /** The active cad model for alignment */
  activeCad: IElementModel3dStream;

  /** The bounding box of the cad model in world */
  cadBox: Box3;

  /** First screen in split screen (left or top screen) */
  firstScreen: HTMLDivElement;

  /** Second  screen in split screen (right or bottom screen) */
  secondScreen: HTMLDivElement;
};

/** @returns the scene for align the cad to sheet in 2d */
export function AlignSheetToCadScene({
  activeSheet,
  activeCad,
  cadBox,
}: AlignSheetToCadSceneProps): JSX.Element {
  const alignmentLayout = useAppSelector(selectSheetToCadAlignmentLayout);
  const { firstScreen, secondScreen } = useOverlayElements();

  return alignmentLayout === AlignmentViewLayout.splitScreen &&
    firstScreen &&
    secondScreen ? (
    <AlignSheetToCadSplitScreen
      {...{ activeSheet, activeCad, cadBox, firstScreen, secondScreen }}
    />
  ) : (
    <AlignSheetToCadOverlayScene {...{ activeSheet, activeCad, cadBox }} />
  );
}

/** @returns the split-screen scene for align the cad to sheet in 2d */
function AlignSheetToCadSplitScreen({
  activeSheet,
  activeCad,
  cadBox,
  firstScreen,
  secondScreen,
}: AlignSheetToCadSplitScreenProps): JSX.Element {
  const background = useThree((s) => s.scene.background);
  // first camera is for top or left screen, second is for bottom or right screen
  const firstCamera = useNewOrthographicCamera();
  const secondCamera = useNewOrthographicCamera();

  const sheetElevation = useAppSelector(selectSheetElevation);

  const cadModel = useCached3DObject(activeCad);
  useObjectVisibility(cadModel, true);

  const cadCenteringData = useCenterCameraOnCadModel(
    cadModel,
    cadBox,
    secondScreen,
  );
  const sheetCenteringData = useCenterCameraOnPlaceholders({
    sheetElement: activeSheet,
    placeholders: new Array<IElementImg360>(),
    viewAspectRatio: firstScreen.clientWidth / firstScreen.clientHeight,
  });

  useNonExhaustiveEffect(() => {
    centerOrthoCamera(firstCamera, sheetCenteringData);
    centerOrthoCamera(secondCamera, cadCenteringData);
  }, []);

  const cameras = useMemo(() => {
    return [firstCamera, secondCamera];
  }, [firstCamera, secondCamera]);

  useActiveCameras(cameras);

  const sheet = useCached3DObject(activeSheet);
  const sheetPosition = useAppSelector(
    selectIElementWorldPosition(activeSheet.id),
  );

  useCadTomographicMaterial(cadModel);
  const isCrossSectionEnabled = useAppSelector(
    selectCrossSectionEnabledForTomographicView,
  );
  const clippingPlanes = useClippingPlanesAtModelElevation(
    cadModel,
    sheetElevation,
    isCrossSectionEnabled,
  );

  const [controlsEnabled, setControlsEnabled] = useState(true);

  const dispatch = useAppDispatch();
  const alignmentAnchorPositions = useAppSelector(
    selectAlignmentAnchorPositions,
  );

  // compute split screen alignment when there is change in anchor positions
  useEffect(() => {
    const alignmentTransform = computeSplitScreenAlignment(
      alignmentAnchorPositions,
      true,
    );
    if (alignmentTransform) {
      dispatch(setAreaToCadTransform(alignmentTransform));
    }
  }, [alignmentAnchorPositions, dispatch]);

  const changeModelAnchor1Position = useCallback(
    (position: Vector3Tuple) => {
      dispatch(setReferenceElementAnchor1(position));
    },
    [dispatch],
  );
  const changeModelAnchor2Position = useCallback(
    (position: Vector3Tuple) => {
      dispatch(setReferenceElementAnchor2(position));
    },
    [dispatch],
  );
  const changeSheetAnchor1Position = useCallback(
    (position: Vector3Tuple) => {
      dispatch(setMovingElementAnchor1(position));
    },
    [dispatch],
  );
  const changeSheetAnchor2Position = useCallback(
    (position: Vector3Tuple) => {
      dispatch(setMovingElementAnchor2(position));
    },
    [dispatch],
  );

  return (
    <>
      <View
        camera={firstCamera}
        trackingElement={firstScreen}
        background={background}
        hasSeparateScene
      >
        <AnchorPairPlacement
          onPointerDown={() => setControlsEnabled(false)}
          onPointerUp={() => setControlsEnabled(true)}
          anchor1Position={alignmentAnchorPositions.movingElementAnchor1}
          anchor2Position={alignmentAnchorPositions.movingElementAnchor2}
          changeAnchor1Position={changeSheetAnchor1Position}
          changeAnchor2Position={changeSheetAnchor2Position}
        >
          <SheetRenderer sheet={sheet} />
        </AnchorPairPlacement>
        <EffectPipeline>
          <FilteredRenderPass filter={(o) => o.name === activeSheet.id} />
          <DesaturatePass />
          <FilteredRenderPass
            filter={(o) => o.name !== activeSheet.id}
            clear={false}
            clearDepth={false}
          />
          <CopyToScreenPass />
        </EffectPipeline>
        <SheetModeControls
          camera={firstCamera}
          referencePlaneHeight={sheetPosition[1]}
          enabled={controlsEnabled}
        />
      </View>
      <View
        camera={secondCamera}
        trackingElement={secondScreen}
        background={background}
        hasSeparateScene
      >
        <ambientLight intensity={1.0} />
        <AnchorPairPlacement
          onPointerDown={() => setControlsEnabled(false)}
          onPointerUp={() => setControlsEnabled(true)}
          anchor1Position={alignmentAnchorPositions.referenceElementAnchor1}
          anchor2Position={alignmentAnchorPositions.referenceElementAnchor2}
          changeAnchor1Position={changeModelAnchor1Position}
          changeAnchor2Position={changeModelAnchor2Position}
        >
          <CadRenderer
            cadModel={cadModel}
            clippingPlanes={clippingPlanes}
            clippingInLocalTransform={clippingPlanes.length > 0}
          />
        </AnchorPairPlacement>
        <EffectPipeline>
          <ClearPass
            clearColor={background instanceof Color ? background : "0xffffff"}
          />
          <TomographicModelPass camera={secondCamera} bias={0.05} />
          <FilteredRenderPass
            filter={(o) => o.userData.type !== RenderDispatch.CadModel}
            clear={false}
            clearDepth={false}
          />
          <CopyToScreenPass />
        </EffectPipeline>
        <SheetModeControls
          camera={secondCamera}
          referencePlaneHeight={sheetPosition[1]}
          enabled={controlsEnabled}
        />
      </View>
    </>
  );
}

type AlignSheetToCadOverlaySceneProps = {
  /** The active sheet to be aligned to the CAD model */
  activeSheet: IElementGenericImgSheet;

  /** The active cad model for alignment */
  activeCad: IElementModel3dStream;

  /** The bounding box of the cad model in world */
  cadBox: Box3;
};

/** @returns the overlay scene for align the cad to sheet in 2d in overlay*/
function AlignSheetToCadOverlayScene({
  activeSheet,
  activeCad,
  cadBox,
}: AlignSheetToCadOverlaySceneProps): JSX.Element {
  const sheet = useCached3DObject(activeSheet);
  const sheetPosition = useAppSelector(
    selectIElementWorldPosition(activeSheet.id),
  );

  const [controlsEnabled, setControlsEnabled] = useState(true);
  const cadModel = useCached3DObject(activeCad);
  const dispatch = useAppDispatch();

  const incrementalTransform = useAppSelector(selectIncrementalCadTransform);

  useObjectVisibility(cadModel, true);

  // TwoPointAlignment apply this transform to the CAD model, so it
  // can be aligned to the sheet in the view
  const [transform] = useState<AlignmentTransform>(
    () =>
      incrementalTransform ?? {
        position: [0, 0, 0],
        quaternion: [0, 0, 0, 1],
        scale: [1, 1, 1],
      },
  );

  const camera = useNewOrthographicCamera();
  useCameraInCurrentScene(camera);

  const sheetBox = useSheetBoundingBox(sheet);
  const boundingBox = useMemo(
    () =>
      sheetBox
        .clone()
        .applyMatrix4(alignmentTransformToMatrix4(transform))
        .union(cadBox),
    [cadBox, sheetBox, transform],
  );

  const size = useThree((s) => s.size);
  const centeringData = useCenterCameraOnBoundingBoxIn2D(
    boundingBox,
    size.width / size.height,
  );

  const [initialCenteringData] = useState(() => centeringData);
  useEffect(() => {
    centerOrthoCamera(camera, initialCenteringData);
  }, [camera, initialCenteringData]);

  useReproportionCamera(camera);
  useCadTomographicMaterial(cadModel);

  const sheetElevation = useAppSelector(selectSheetElevation);
  const isCrossSectionEnabled = useAppSelector(
    selectCrossSectionEnabledForTomographicView,
  );
  const clippingPlanes = useClippingPlanesAtModelElevation(
    cadModel,
    sheetElevation,
    isCrossSectionEnabled,
  );

  const onTransformChanged = useCallback(
    (position: Vector3, quaternion: Quaternion, scale: Vector3) => {
      const transform: AlignmentTransform = {
        scale: scale.toArray(),
        position: position.toArray(),
        quaternion: quaternionToVector4Tuple(quaternion),
      };

      dispatch(setAreaToCadTransform(transform));
    },
    [dispatch],
  );

  return (
    <>
      <UniformLights />
      <CadRenderer
        cadModel={cadModel}
        clippingPlanes={clippingPlanes}
        clippingInLocalTransform={clippingPlanes.length > 0}
      />
      <TwoPointAlignment
        onPointerDown={() => setControlsEnabled(false)}
        onPointerUp={() => setControlsEnabled(true)}
        onTransformChanged={onTransformChanged}
        {...transform}
      >
        <SheetRenderer sheet={sheet} />
      </TwoPointAlignment>
      <TomographicOverlayViewPipeline
        sheet={activeSheet}
        camera={camera}
        bias={0}
      />
      <Map2DControls
        camera={camera}
        referencePlaneHeight={sheetPosition[1]}
        enabled={controlsEnabled}
        isPrimaryControl={false}
      />
    </>
  );
}
