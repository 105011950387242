// The store need to be imported before any other components that may use it
import { store } from "./store/store";

import { ProjectAlignmentTool } from "@/alignment-tool/project-alignment-tool";
import { DeepLinkContextProvider } from "@/components/common/deep-link/deep-link-context";
import { FileUploadContextProvider } from "@/components/common/file-upload-context/file-uploads-context";
import { ViewRuntimeContextProvider } from "@/components/common/view-runtime-context";
import {
  ErrorHandlingContext,
  useErrorHandlers,
} from "@/errors/components/error-handling-context";
import { useLogging } from "@/logging/logging";
import { NotFoundPage } from "@/pages/not-found";
import { ProjectPage } from "@/pages/project";
import { runtimeConfig } from "@/runtime-config";
import {
  Features,
  parseFeaturesAndValuesFromUrl,
} from "@/store/features/features";
import {
  enableFeature,
  initFeatures,
  selectHasFeature,
} from "@/store/features/features-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { SendToRevitProvider } from "@/tools/send-to/send-to-revit-provider";
import { APP_VERSION } from "@/utils/env-constants";
import { HandleWindowClose } from "@/utils/handle-window-close";
import {
  DialogProvider,
  NoTranslate,
  ToastProvider,
  disableTreeFiltering,
  extendR3FWithLotVComponents,
} from "@faro-lotv/app-component-toolbox";
import {
  HBCookieManagerProvider,
  LocalizeProvider,
} from "@faro-lotv/foreign-observers";
import { AuthProvider } from "@faro-lotv/gate-keepers";
import { initLotvWorkers } from "@faro-lotv/lotv";
import { CoreApiError, useCoreApiClient } from "@faro-lotv/service-wires";
import GltfLoading from "@faro-lotv/web-workers/dist/GltfLoading.worker.js?url";
import PointCloudLoading from "@faro-lotv/web-workers/dist/PointCloudLoading.worker.js?url";
import PotreeNodes from "@faro-lotv/web-workers/dist/PotreeNodes.worker.js?url";
import WSNodes from "@faro-lotv/web-workers/dist/WSNodes.worker.js?url";
import { Box, Stack, ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useLayoutEffect,
} from "react";
import { Provider as StoreProvider } from "react-redux";
import {
  BrowserRouter,
  Route,
  Routes,
  useSearchParams,
} from "react-router-dom";
import { appTheme } from "./app-theme";
import { DataPreparationPage } from "./data-preparation-tool/data-preparation-page";
import { loginStatusCheckError } from "./errors/unrecoverable-error";
import { InspectAndPublishPage } from "./registration-tools/inspect-and-publish/inspect-and-publish-page";
import { PairwiseRegistrationPage } from "./registration-tools/pairwise-registration/pairwise-registration-page";
import { setShowSpinner } from "./store/ui/ui-slice";
import { appId } from "./utils/appid";

extendR3FWithLotVComponents();

/**
 * @returns the initialization logic for the app resources (Store, logging, features)
 */
function AppInit(): null {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const hasFullTree = useAppSelector(selectHasFeature(Features.FullTree));

  // Initialize the logs system
  useLogging();

  useEffect(() => {
    if (hasFullTree) {
      dispatch(disableTreeFiltering());
    }
  }, [dispatch, hasFullTree]);

  useLayoutEffect(() => {
    dispatch(initFeatures(runtimeConfig));

    const enabledFeatures = parseFeaturesAndValuesFromUrl(searchParams);

    // enable features and their optional value based on the url params (url param is not case sensitive)
    for (const feature of enabledFeatures) {
      dispatch(enableFeature(feature));
    }
  }, [dispatch, searchParams]);
  return null;
}

/**
 * @returns a composition of all the global context providers and their initialization
 */
function AllTheProviders({ children }: PropsWithChildren): JSX.Element {
  const { externalLinks } = runtimeConfig;

  return (
    <ThemeProvider theme={appTheme}>
      {/* The CssBaseline is where the root level css is defined in the mui theme */}
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <HBCookieManagerProvider
          cookieManagerUrl={externalLinks.cookieManagerLatestUrl}
        >
          <ViewRuntimeContextProvider>
            <ToastProvider>
              <DialogProvider>
                <ErrorHandlingContext>
                  <StoreProvider store={store}>
                    <EverythingWithStoreAccess>
                      {children}
                    </EverythingWithStoreAccess>
                  </StoreProvider>
                </ErrorHandlingContext>
              </DialogProvider>
            </ToastProvider>
          </ViewRuntimeContextProvider>
        </HBCookieManagerProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

function EverythingWithStoreAccess({
  children,
}: PropsWithChildren): JSX.Element {
  const dispatch = useAppDispatch();
  const { ulmUrl } = runtimeConfig.authentication;

  const { handleErrorWithPage, handleErrorWithToast } = useErrorHandlers();

  const coreApiClient = useCoreApiClient(
    runtimeConfig.backendEndpoints.coreApiUrl,
    appId(),
  );

  const reportAccessError = useCallback(
    (error: CoreApiError) => {
      handleErrorWithPage(loginStatusCheckError(error));
    },
    [handleErrorWithPage],
  );

  const reportFailedLogout = useCallback(
    (error: unknown) => {
      handleErrorWithToast({
        title: "Failed to logout user. Please try again or reload the page",
        error,
      });
    },
    [handleErrorWithToast],
  );

  return (
    <LocalizeWrapper>
      <BrowserRouter>
        <AuthProvider
          ulmUrl={ulmUrl}
          coreApiClient={coreApiClient}
          onLogout={() => location.reload()}
          onLoginFailed={reportAccessError}
          onLogoutFailed={reportFailedLogout}
          onShowLoadingScreen={(showSpinner) =>
            dispatch(setShowSpinner(showSpinner))
          }
        >
          <AppInit />

          <HandleWindowClose />

          <FileUploadContextProvider>
            <SendToRevitProvider>{children}</SendToRevitProvider>
          </FileUploadContextProvider>
        </AuthProvider>
      </BrowserRouter>
    </LocalizeWrapper>
  );
}

/**
 * @returns the localization provider if the app has the feature enabled
 */
function LocalizeWrapper({ children }: PropsWithChildren): JSX.Element {
  const shouldTranslateApp = useAppSelector(
    selectHasFeature(Features.Localize),
  );

  const { projectKey, saveNewTranslations } =
    runtimeConfig.externalServices.localize;

  return shouldTranslateApp ? (
    <LocalizeProvider
      projectKey={projectKey}
      // Widget is shown for debugging purposes until the new language selector is implemented
      // https://faro01.atlassian.net/browse/SWEB-5128
      initOptions={{
        saveNewPhrases: saveNewTranslations,
        disableWidget: false,
      }}
    >
      {children}
    </LocalizeProvider>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
}

/**
 * @returns Entry point of app
 */
export function App(): JSX.Element {
  /**
   * Setting the workers location to make them available to LotV during run time
   * Configuration to make them available in public, is under config-overrides.js
   */
  initLotvWorkers({
    GltfLoading,
    PointCloudLoading,
    PotreeNodes,
    WSNodes,
  });

  // Log app version at start
  console.log(`App version: ${APP_VERSION}`);

  return (
    <AllTheProviders>
      <Stack sx={{ height: "100%" }}>
        <Box component="div" sx={{ height: "100%" }}>
          <Routes>
            {/* Viewer */}
            <Route
              path="/project/:projectId"
              element={
                <DeepLinkContextProvider>
                  <ProjectPage />
                </DeepLinkContextProvider>
              }
            />

            {/* Alignment tool */}
            <Route
              path="/alignment/project/:projectId"
              element={
                // TODO: Include ProjectAlignmentTool into translations
                // See https://faro01.atlassian.net/browse/SWEB-5172
                <NoTranslate>
                  <ProjectAlignmentTool />
                </NoTranslate>
              }
            />

            {/* Temporary route to test the prototype of the manual cloud2cloud alignment tool.
             * Subsequent tasks will apply a better design to access the tool from the viewer. */}
            <Route
              path="/manual-alignment/project/:projectId"
              element={
                // TODO: Include PairwiseRegistrationPage into translations
                // See https://faro01.atlassian.net/browse/SWEB-5173
                <NoTranslate>
                  <PairwiseRegistrationPage />
                </NoTranslate>
              }
            />

            {/* Temporary route to test the multi-cloud registration.
             * Subsequent tasks will apply a better design to access the tool from the viewer. */}
            <Route
              path="/multi-registration-result/project/:projectId"
              element={
                // TODO: Include InspectAndPublishPage into translations
                // See https://faro01.atlassian.net/browse/SWEB-5174
                <NoTranslate>
                  <InspectAndPublishPage />
                </NoTranslate>
              }
            />

            {/* The data preparation tool, aka the staging area. */}
            <Route
              path="/data-preparation/project/:projectId"
              element={
                // TODO: Include DataPreparationPage into translations
                // See https://faro01.atlassian.net/browse/SWEB-5175
                <NoTranslate>
                  <DataPreparationPage />
                </NoTranslate>
              }
            />

            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Box>
      </Stack>
    </AllTheProviders>
  );
}
