import { Features, selectHasFeature } from "@/store/features/features-slice";
import { useAppSelector } from "@/store/store-hooks";
import { PropsWithChildren, useState } from "react";
import { AnnotationWithNewAttachments } from "./annotation-props";
import { ExternalAnnotation } from "./external-annotation";
import { SphereAnnotation } from "./sphere-annotation";

export interface CreateAnnotationFormProps {
  /** Callback called when the dialog is closed (successfully or non-successfully). use to clear the annotationToCreate. */
  onClose(): void;

  /** Callback called when the user click the save button */
  onSave(details: AnnotationWithNewAttachments): Promise<void>;
}
/** The annotation type value that is corresponding to Sphere XG annotation */
const SPHERE_XG_ANNOTATION_VALUE = "FARO Sphere XG";

/**
 * @returns A form to persist an annotation to the project
 */
export function CreateAnnotationForm({
  children,
  onClose,
  onSave,
}: PropsWithChildren<CreateAnnotationFormProps>): JSX.Element {
  const [annotationType, setAnnotationType] = useState(
    SPHERE_XG_ANNOTATION_VALUE,
  );
  const annotationTypeOptions = [
    {
      key: "sphereXgAnnotation",
      value: SPHERE_XG_ANNOTATION_VALUE,
      label: SPHERE_XG_ANNOTATION_VALUE,
    },
    {
      key: "externalMarkupAnnotation",
      value: "External Markup",
      label: "External Markup",
    },
  ];

  const externalMarkupCreationEnabled = useAppSelector(
    selectHasFeature(Features.ThirdPartyAnnotation),
  );

  const enableExternalMarkups =
    externalMarkupCreationEnabled && annotationTypeOptions.length > 0;

  if (!enableExternalMarkups || annotationType === SPHERE_XG_ANNOTATION_VALUE) {
    return (
      <SphereAnnotation
        onClose={onClose}
        onSave={onSave}
        enableExternalMarkups={enableExternalMarkups}
        annotationType={annotationType}
        onAnnotationTypeChange={setAnnotationType}
        annotationTypeOptions={annotationTypeOptions}
      >
        {children}
      </SphereAnnotation>
    );
  }
  return (
    <ExternalAnnotation
      onClose={onClose}
      onSave={onSave}
      enableExternalMarkups={enableExternalMarkups}
      annotationType={annotationType}
      onAnnotationTypeChange={setAnnotationType}
      annotationTypeOptions={annotationTypeOptions}
    />
  );
}
