import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { Tag } from "@/store/tags/tags-slice";
import {
  AnnotationEditorButtons,
  AnnotationEditorHeader,
  AnnotationEditorTypeSelection,
  AnnotationTypeProps,
  DatePicker,
  Dropdown,
  DropdownSelect,
  dropDownStyle,
  FaroChipTag,
  FaroRichTextEditor,
  FilterMenuContent,
  neutral,
  Option,
} from "@faro-lotv/flat-ui";
import { assert } from "@faro-lotv/foundation";
import { Divider, Grid, Stack, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { AnnotationProps } from "./annotation-props";
import { CreateAnnotationFormProps } from "./create-annotation-form";

type ExternalAnnotationProps = CreateAnnotationFormProps &
  AnnotationTypeProps & {
    /** The description of the BCF Topic to display when displaying the description text field for the first time */
    initialDescription?: string;
  };

/**
 * @returns an external annotation creation form
 */
export function ExternalAnnotation({
  onClose,
  annotationType,
  onAnnotationTypeChange,
  annotationTypeOptions,
}: ExternalAnnotationProps): JSX.Element {
  // setIsSaving will be added later in a future issue CADBIM-777,
  // This value indicates if the annotation is in the process of being saved.
  const [isSaving] = useState(false);

  const [title, setTitle] = useState("Title");
  const [assignee, setAssignee] = useState<string>();
  const [status, setStatus] = useState<string>();
  const [dueDate, setDueDate] = useState<Date>();
  const [description, setDescription] = useState<string>();
  const [priority, setPriority] = useState<string>();
  const [stage, setStage] = useState<string>();
  const [selectedLabels, setSelectedLabels] = useState<Tag[]>([]);

  assert(
    annotationType !== undefined &&
      onAnnotationTypeChange &&
      annotationTypeOptions,
    "Invalid props",
  );

  return (
    <Stack
      direction="column"
      sx={{
        width: "522px",
        backgroundColor: neutral[999],
        p: 2,
        borderRadius: "4px",
        pointerEvents: isSaving ? "none" : undefined,
        flexGrow: 1,
        gap: 3,
        // Allow the main content to scroll if there's not enough space
        overflowY: "auto",
        // but do not add an horizontal scrollbar when the main content needs to scroll
        overflowX: "clip",
      }}
    >
      <>
        <Grid item xs={6} py={0}>
          <AnnotationEditorTypeSelection
            value={annotationType}
            options={annotationTypeOptions}
            onChange={(e) => onAnnotationTypeChange(e.target.value)}
            disabled={isSaving}
          />
        </Grid>
        <Divider sx={{ bgcolor: neutral[0] }} />
      </>
      <AnnotationEditorHeader
        title={title}
        autoFocusTitle={false}
        onTitleChange={setTitle}
        isSaving={isSaving}
      />
      <ExternalAnnotationForm
        allowEdition={isSaving}
        assignee={assignee}
        description={description}
        dueDate={dueDate}
        status={status}
        priority={priority}
        stage={stage}
        selectedLabels={selectedLabels}
        onDescriptionChange={setDescription}
        onStageChange={setStage}
        onAssigneeChange={setAssignee}
        onDueDateChange={setDueDate}
        onStatusChange={setStatus}
        onPriorityChange={setPriority}
        onSelectedLabelsChange={setSelectedLabels}
      />
      <AnnotationEditorButtons
        isConfirmButtonDisabled
        isSaving={isSaving}
        confirmButtonText="Create"
        onCancelButtonClick={onClose}
        onConfirmButtonClick={() => {}}
      />
    </Stack>
  );
}

type ExternalAnnotationFormProps = Pick<
  AnnotationProps,
  "assignee" | "status" | "dueDate" | "description"
> & {
  /** Indicates if some changes to should to annotation's values should be allowed */
  allowEdition: boolean;

  /** The priority of a BCF Topic */
  priority?: string;

  /** The stage of a BCF Topic */
  stage?: string;

  /** The selected labels of a BCF Topic */
  selectedLabels?: Tag[];

  /** callback function called when the value of the assignee has changed */
  onAssigneeChange?(assignee: string): void;

  /** callback function called when the value of the description has changed */
  onDescriptionChange?(description: string): void;

  /** callback function called when the value of the status has changed */
  onStatusChange?(status: string): void;

  /** callback function called when the value of the stage has changed */
  onStageChange?(stage: string): void;

  /** callback function called when the value of the priority has changed */
  onPriorityChange?(priority: string): void;

  /** callback function called when the value of the due date has changed */
  onDueDateChange?(dueDate: Date): void;

  /** callback function called when the value of the selected labels have changed */
  onSelectedLabelsChange?(selectedLabels: Tag[]): void;
};

function ExternalAnnotationForm({
  allowEdition,
  assignee,
  description,
  dueDate,
  status,
  priority,
  stage,
  selectedLabels,
  onAssigneeChange,
  onDescriptionChange,
  onStatusChange,
  onStageChange,
  onPriorityChange,
  onDueDateChange,
  onSelectedLabelsChange,
}: ExternalAnnotationFormProps): JSX.Element {
  const selectedLabelsOptions = useMemo(
    () =>
      selectedLabels?.map((label) => ({
        key: label.id,
        value: label.name,
        label: label.name,
      })),
    [selectedLabels],
  );
  const assigneeOptions: Option[] = [];
  const priorityOptions: Option[] = [];
  const stageOptions: Option[] = [];
  const statusOptions: Option[] = [];
  const labelsOptions: Option[] = [];
  const { handleErrorWithToast } = useErrorHandlers();
  return (
    <Stack
      sx={{
        // Add a scrollbar only to the main content if needed
        overflow: "auto",
        flexGrow: 1,
        gap: 3,
      }}
    >
      <FaroRichTextEditor
        initialText={description}
        dark
        fullWidth
        readOnly={allowEdition}
        label="Description"
        placeholder="Insert a description"
        onError={(error) =>
          handleErrorWithToast({ title: "Error in description", error })
        }
        onChange={onDescriptionChange}
        sx={{
          height: "120px",
        }}
      />
      <Grid container spacing={3}>
        <Grid item xs={6} py={0}>
          <Dropdown
            label="Priority"
            disabled={allowEdition}
            value={priority}
            options={priorityOptions}
            dark
            fullWidth
            sx={dropDownStyle}
            onChange={
              onPriorityChange === undefined
                ? undefined
                : (event) => onPriorityChange(event.target.value)
            }
            shouldCapitalize={false}
          />
        </Grid>
        <Grid item xs={6} py={0}>
          <Dropdown
            label="Stage"
            disabled={allowEdition}
            value={stage}
            options={stageOptions}
            dark
            fullWidth
            sx={dropDownStyle}
            onChange={
              onStageChange === undefined
                ? undefined
                : (event) => onStageChange(event.target.value)
            }
            shouldCapitalize={false}
          />
        </Grid>
        <Grid item xs={6} py={0}>
          <Dropdown
            label="Assignee"
            disabled={allowEdition}
            value={assignee}
            options={assigneeOptions}
            dark
            fullWidth
            sx={dropDownStyle}
            onChange={
              onAssigneeChange === undefined
                ? undefined
                : (event) => onAssigneeChange(event.target.value)
            }
            shouldCapitalize={false}
          />
        </Grid>

        <Grid item xs={6} py={0}>
          <Dropdown
            label="Status"
            disabled={allowEdition}
            value={status}
            options={statusOptions}
            dark
            fullWidth
            sx={dropDownStyle}
            onChange={
              onStatusChange === undefined
                ? undefined
                : (event) => onStatusChange(event.target.value)
            }
            shouldCapitalize={false}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            label="Due date"
            disabled={allowEdition}
            initialDate={dueDate ?? new Date()}
            variant="Dark"
            formSx={{ width: "100%" }}
            onChange={
              onDueDateChange === undefined
                ? undefined
                : (date) => (date ? onDueDateChange(date) : {})
            }
            isClearable
          />
        </Grid>
        <Grid item xs={6} py={0}>
          <DropdownSelect
            dark
            disabled={allowEdition}
            label="Labels"
            sx={dropDownStyle}
            renderValue={() => {
              if (!selectedLabels?.length) {
                return (
                  <Typography
                    sx={{
                      fontStyle: "italic",
                      mr: 1,
                    }}
                  >
                    Select labels
                  </Typography>
                );
              }

              return (
                <FaroChipTag
                  size="small"
                  label={`${selectedLabels.length}`}
                  dark
                />
              );
            }}
          >
            <FilterMenuContent
              dark
              selectedOptions={selectedLabelsOptions ?? []}
              options={labelsOptions}
              setSelectedOptions={(options) => {
                if (onSelectedLabelsChange !== undefined) {
                  onSelectedLabelsChange(
                    options.map((o) => ({
                      id: o.key,
                      name: typeof o.label === "string" ? o.label : "",
                    })),
                  );
                }
              }}
            />
          </DropdownSelect>
        </Grid>
      </Grid>
    </Stack>
  );
}
