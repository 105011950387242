import { createAreaAlignmentMutations } from "@/alignment-tool/project-alignment-mutations";
import { IDENTITY } from "@/alignment-tool/store/alignment-slice";
import {
  alignmentTransformToMatrix4,
  matrix4ToAlignmentTransform,
} from "@/alignment-tool/utils/alignment-transform";
import { useCurrentProjectApiClient } from "@/components/common/project-provider/project-loading-context";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { changeMode } from "@/store/mode-slice";
import { AlignmentViewLayout } from "@/store/modes/sheet-to-cad-alignment-mode-slice";
import {
  selectIncrementalSheetTransform,
  selectSheetToCloudAlignmentLayout,
  selectSheetToCloudAlignmentSheetElevation,
  selectSheetToCloudAlignmentStep,
} from "@/store/modes/sheet-to-cloud-alignment-mode-selectors";
import {
  resetSheetToCloudAlignment,
  setSheetToCloudAlignmentLayout,
  SheetToCloudAlignmentStep,
} from "@/store/modes/sheet-to-cloud-alignment-mode-slice";
import { setActiveElement } from "@/store/selections-slice";
import { store } from "@/store/store";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { setShowSpinner } from "@/store/ui/ui-slice";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import { useToast } from "@faro-lotv/flat-ui";
import { isIElementAreaSection } from "@faro-lotv/ielement-types";
import {
  fetchProjectIElements,
  selectAncestor,
  selectIElement,
  selectIElementProjectApiLocalPose,
  selectIElementWorldPosition,
} from "@faro-lotv/project-source";
import { useCallback } from "react";
import { AlignmentViewLayoutToggle } from "../alignment-modes-commons/alignment-view-layout-toggle";
import { useSheetSelectedForAlignment } from "../mode-data-context";
import { SheetToCloudAlignmentOverlayScreen } from "./sheet-to-cloud-alignment-overlay-screen";
import { SheetToCloudAlignmentProgressBar } from "./sheet-to-cloud-alignment-progreess-bar";
import { SheetToCloudAlignmentSplitScreen } from "./sheet-to-cloud-alignment-split-screen";

/** @returns The overlay for the sheet to cloud alignment mode */
export function SheetToCloudAlignmentModeOverlay(): JSX.Element {
  const dispatch = useAppDispatch();
  const { openToast } = useToast();
  const { handleErrorWithToast } = useErrorHandlers();

  const client = useCurrentProjectApiClient();
  const incrementalTransform = useAppSelector(selectIncrementalSheetTransform);
  const sheetElevation = useAppSelector(
    selectSheetToCloudAlignmentSheetElevation,
  );
  const sheet = useSheetSelectedForAlignment("sheetToCloud");

  const applyAreaMutation = useCallback(async () => {
    dispatch(setShowSpinner(true));

    const sectionArea = selectAncestor(
      selectIElement(sheet.id)(store.getState()),
      isIElementAreaSection,
    )(store.getState());

    if (!sectionArea) {
      throw new Error("Section Area not found for selected sheet.");
    }
    const sectionAreaWorldMatrix = selectIElementWorldMatrix4(sectionArea.id)(
      store.getState(),
    );
    const sectionAreaWorldTransform = matrix4ToAlignmentTransform(
      sectionAreaWorldMatrix,
    );

    const sheetWorldPosition = selectIElementWorldPosition(sheet.id)(
      store.getState(),
    );

    const transform = incrementalTransform ?? IDENTITY;

    const sheetToCloudMatrix = alignmentTransformToMatrix4(transform);
    const alignmentTransform = matrix4ToAlignmentTransform(
      sheetToCloudMatrix.multiply(sectionAreaWorldMatrix),
    );

    // We are computing the delta elevation to apply to the section area
    alignmentTransform.position[1] =
      sheetElevation +
      sectionAreaWorldTransform.position[1] -
      sheetWorldPosition[1];

    const localTransform = selectIElementProjectApiLocalPose(
      sectionArea,
      alignmentTransformToMatrix4(alignmentTransform),
    )(store.getState());

    try {
      await client.applyMutations(
        createAreaAlignmentMutations(sectionArea, {
          position: [
            localTransform.pos.x,
            localTransform.pos.y,
            localTransform.pos.z,
          ],
          quaternion: [
            localTransform.rot.x,
            localTransform.rot.y,
            localTransform.rot.z,
            localTransform.rot.w,
          ],
          scale: [
            localTransform.scale.x,
            localTransform.scale.y,
            localTransform.scale.z,
          ],
        }),
      );
    } catch (error) {
      handleErrorWithToast({
        title: "Failed to save new alignment",
        error,
      });

      dispatch(setShowSpinner(false));

      return;
    }

    // Fetch the changed section area sub-tree and update the local copy of the project
    // that new alignment will be used without reloading whole project
    dispatch(
      fetchProjectIElements({
        fetcher: async () => {
          // Refresh the area node to get new transform
          return await client.getAllIElements({
            ancestorIds: [sectionArea.id],
          });
        },
      }),
    );

    // after new alignment applied user will be redirected to 3D view in "overview" mode
    // force sheet to be new active area to immediately show results of alignment
    dispatch(setActiveElement(sheet.id));

    dispatch(setShowSpinner(false));
    openToast({
      title: "Alignment Completed",
      variant: "success",
    });

    // after alignment force switch to overview mode as most convenient to validate alignment result in main 3D view
    dispatch(changeMode("overview"));

    // at the end of alignment cycle reset temporary data to prevent reusing it in the next session of alignment
    dispatch(resetSheetToCloudAlignment());
  }, [
    client,
    dispatch,
    handleErrorWithToast,
    incrementalTransform,
    openToast,
    sheet.id,
    sheetElevation,
  ]);

  const step = useAppSelector(selectSheetToCloudAlignmentStep);
  const alignmentViewLayout = useAppSelector(selectSheetToCloudAlignmentLayout);
  const changeAlignmentViewLayout = useCallback(
    (value: AlignmentViewLayout) =>
      dispatch(setSheetToCloudAlignmentLayout(value)),
    [dispatch],
  );

  return (
    <>
      <SheetToCloudAlignmentProgressBar applyAlignment={applyAreaMutation} />
      {step === SheetToCloudAlignmentStep.alignIn2d &&
        alignmentViewLayout === AlignmentViewLayout.splitScreen && (
          <SheetToCloudAlignmentSplitScreen />
        )}
      {step === SheetToCloudAlignmentStep.alignIn2d &&
        alignmentViewLayout === AlignmentViewLayout.overlay && (
          <SheetToCloudAlignmentOverlayScreen />
        )}
      {step === SheetToCloudAlignmentStep.alignIn2d && (
        <AlignmentViewLayoutToggle
          alignmentLayout={alignmentViewLayout}
          changeAlignmentScreenLayout={changeAlignmentViewLayout}
        />
      )}
    </>
  );
}
