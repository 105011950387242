import {
  LocalRevisionEdge,
  RegistrationMetrics,
  validateRegistrationMetrics,
} from "@/registration-tools/utils/multi-registration-report";
import {
  GUID,
  validateNotNullishObject,
  validateOfType,
} from "@faro-lotv/foundation";
import { clearStore } from "@faro-lotv/project-source";
import {
  CaptureTreeEntityRevision,
  RegistrationEdgeRevision,
} from "@faro-lotv/service-wires";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  RevisionTransformCache,
  generateRevisionTransformCache,
} from "./revision-transform-cache";

export type EntityMap = Record<GUID, CaptureTreeEntityRevision | undefined>;

/** The data we expect to be contained in a registration edge on the capture tree. */
export type RegistrationEdgeMetricsData = {
  metrics: RegistrationMetrics;
};

/**
 * @param data The data to validate
 * @returns true if the data is a valid `RegistrationEdgeMetricsData` object, else false.
 */
export function isRegistrationEdgeMetricsData(
  data: unknown,
): data is RegistrationEdgeMetricsData {
  return (
    validateNotNullishObject<RegistrationEdgeMetricsData>(
      data,
      "RegistrationEdgeData",
    ) && validateOfType(data, "metrics", validateRegistrationMetrics)
  );
}

/** A registration edge which has the registration metrics in its data field. */
export type RegistrationEdgeWithMetrics =
  RegistrationEdgeRevision<RegistrationEdgeMetricsData>;

export type EdgesMap = Record<GUID, LocalRevisionEdge | undefined>;

type RevisionState = {
  /** A map from IDs to the corresponding entity in the revision. */
  entityMap: EntityMap;

  /** A map from IDs to the corresponding registration edge in the revision. */
  edgesMap: EdgesMap;

  /** The cached world transforms of the entities. */
  transformCache: RevisionTransformCache;
};

export const initialState: Readonly<RevisionState> = Object.freeze({
  entityMap: {},
  edgesMap: {},
  transformCache: {},
});

export const revisionSlice = createSlice({
  initialState,
  name: "revision",

  reducers: {
    /**
     * @param state The current application state
     * @param action The revision entities to add to the store.
     */
    addEntities(state, action: PayloadAction<CaptureTreeEntityRevision[]>) {
      // Create a new map, as the store should not be mutated directly
      const newMap: EntityMap = {
        // Add old entries
        ...state.entityMap,
      };

      // Add new entries
      action.payload.forEach((entity) => {
        newMap[entity.id] = entity;
      });

      // Re-generate the world transform cache
      const transformCache = generateRevisionTransformCache(newMap);

      state.entityMap = newMap;
      state.transformCache = transformCache;
    },

    /**
     * @param state The current application state
     * @param action The revision edges to add to the store.
     */
    addRevisionEdges(
      state,
      action: PayloadAction<RegistrationEdgeWithMetrics[]>,
    ) {
      // Create a new map, as the store should not be mutated directly
      // we empty the map and fill it with the new data to avoid consuming more memory.
      const newMap: EdgesMap = {};

      for (const edge of action.payload) {
        // Convert to the same format used in the registration report
        const localRevisionEdge: LocalRevisionEdge = {
          ...edge,
          metrics: edge.data.metrics,
        };

        // Add the LocalRevisionEdge object to the newMap
        newMap[edge.id] = localRevisionEdge;
      }

      state.edgesMap = newMap;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearStore, () => initialState);
  },
});

export const { addEntities, addRevisionEdges } = revisionSlice.actions;

export const revisionReducer = revisionSlice.reducer;
