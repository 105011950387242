import {
  PointCloudAnalysis,
  ReferencePlaneType,
  setAnalysisElevation,
  setAnalysisReferencePlaneType,
  setAnalysisShowReferencePlane,
} from "@/store/point-cloud-analysis-tool-slice";
import { useAppDispatch } from "@/store/store-hooks";
import {
  convertUnit,
  MeasurementUnits,
} from "@faro-lotv/app-component-toolbox";
import {
  Dropdown,
  FaroSwitch,
  FaroText,
  neutral,
  TextField,
} from "@faro-lotv/flat-ui";
import { validateEnumValue } from "@faro-lotv/foundation";
import { SupportedUnitsOfMeasure } from "@faro-lotv/ielement-types";
import { Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

type ColormapReferencePlaneProps = {
  /** Active analysis object to be modified. */
  analysis: PointCloudAnalysis;

  /** Current selected unit of measure used to display the distances */
  unitOfMeasure: SupportedUnitsOfMeasure;
};

const planeOptions = [
  { key: "flatness", label: "Flatness", value: ReferencePlaneType.flatness },
  {
    key: "elevation",
    label: "Compare to elevation",
    value: ReferencePlaneType.elevation,
  },
  {
    key: "levelness",
    label: "Levelness",
    value: ReferencePlaneType.levelness,
  },
  { key: "plumbness", label: "Plumbness", value: ReferencePlaneType.plumbness },
];

function referencePlaneTypeFromString(
  value: string,
): ReferencePlaneType | undefined {
  return validateEnumValue(value, ReferencePlaneType) ? value : undefined;
}

/** @returns Colormap reference plane UI panel */
export function ColormapReferencePlanePanel({
  analysis,
  unitOfMeasure,
}: ColormapReferencePlaneProps): JSX.Element {
  const dispatch = useAppDispatch();

  const [planeOption, setPlaneOption] = useState<string>(
    analysis.referencePlaneType,
  );

  const changePlaneOption = useCallback(
    (newPlaneOption: string) => {
      setPlaneOption(newPlaneOption);
      const referencePlaneType = referencePlaneTypeFromString(newPlaneOption);
      if (referencePlaneType) {
        dispatch(
          setAnalysisReferencePlaneType({
            analysisId: analysis.id,
            referencePlaneType,
          }),
        );
      }
    },
    [analysis.id, dispatch],
  );

  const [elevationText, setElevationText] = useState("");
  const [elevationChanged, setElevationChanged] = useState(false);

  useEffect(() => {
    // reset elevationChanged flag when unit of measure is changed
    setElevationChanged(false);
  }, [unitOfMeasure]);

  useEffect(() => {
    if (analysis.elevation === undefined) return;

    // avoid reset text when elevation is changed from the textbox
    if (!elevationChanged) {
      setElevationText(
        unitOfMeasure === "metric"
          ? analysis.elevation.toFixed(3)
          : convertUnit(
              analysis.elevation,
              MeasurementUnits.meters,
              MeasurementUnits.feet,
            ).toFixed(3),
      );
    }
  }, [analysis.elevation, elevationChanged, unitOfMeasure]);

  const [elevationError, setElevationError] = useState("");
  const changeElevation = useCallback(
    (newText: string) => {
      setElevationText(newText);
      const newElevation = Number(newText);
      if (isNaN(newElevation)) {
        setElevationError("Invalid number");
      } else {
        const elevation =
          unitOfMeasure === "metric"
            ? newElevation
            : convertUnit(
                newElevation,
                MeasurementUnits.feet,
                MeasurementUnits.meters,
              );
        setElevationError("");
        setElevationChanged(true);
        dispatch(setAnalysisElevation({ analysisId: analysis.id, elevation }));
      }
    },
    [analysis.id, dispatch, unitOfMeasure],
  );

  const [showReferencePlaneChecked, setShowReferencePlaneChecked] =
    useState(false);
  useEffect(() => {
    setShowReferencePlaneChecked(analysis.showReferencePlane ?? false);
  }, [analysis.showReferencePlane]);
  const toggleShowReferencePlane = useCallback(
    (showReferencePlane: boolean) => {
      setShowReferencePlaneChecked(showReferencePlane);
      dispatch(
        setAnalysisShowReferencePlane({
          analysisId: analysis.id,
          showReferencePlane,
        }),
      );
    },
    [analysis.id, dispatch],
  );

  return (
    <Stack spacing={1} sx={{ width: "250px" }}>
      <FaroSwitch
        label="Show reference plane"
        dark
        fullWidth
        checked={showReferencePlaneChecked}
        onToggle={() => toggleShowReferencePlane(!showReferencePlaneChecked)}
      />
      <Dropdown
        options={planeOptions}
        value={planeOption}
        dark
        onChange={(e) => changePlaneOption(e.target.value)}
      />
      {planeOption === ReferencePlaneType.elevation && (
        <Stack direction="row" spacing={1} alignItems="center">
          <FaroText variant="labelL" sx={{ color: neutral[0], width: "150px" }}>
            Elevation ({unitOfMeasure === "metric" ? "m" : "ft"})
          </FaroText>
          <TextField
            dark
            text={elevationText}
            error={elevationError}
            onTextChanged={changeElevation}
          />
        </Stack>
      )}
    </Stack>
  );
}
