import { useUpdateProjectMetadata } from "@/components/common/project-provider/use-project-metadata";
import { THRESHOLD_SET_STATIONARY } from "@/registration-tools/common/registration-report/registration-thresholds";
import { ThresholdSetProvider } from "@/registration-tools/common/registration-report/threshold-set-context";
import { useAppSelector } from "@/store/store-hooks";
import { FaroStepper } from "@faro-lotv/flat-ui";
import {
  getProjectPreparedDataPage,
  selectDashboardUrl,
} from "@faro-lotv/project-source";
import {
  RegistrationRevision,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { Stack } from "@mui/material";
import { isEqual } from "lodash";
import { useState } from "react";
import { SphereViewerToolHeaderBar } from "../components/ui/tool-header-bar";
import {
  useDataPreparationSteps,
  useLatestDataPreparationStep,
} from "./data-preparation-stepper";
import { InspectAndPublishView } from "./inspect-and-publish/inspect-and-publish-view";
import { useLoadRevisionPointCloudStreams } from "./loading/use-load-revision-point-cloud-streams";
import { RegistrationView } from "./registration/registration-view";
import { selectRevisionScans } from "./store/revision-selectors";

export type DataPreparationUiProps = {
  /** The revision that is used for the data preparation. */
  revision: RegistrationRevision;
};

/**
 * @returns UI for the data preparation page.
 */
export function DataPreparationUi({
  revision,
}: DataPreparationUiProps): JSX.Element {
  const { projectApiClient } = useApiClientContext();

  // Non-blocking: Load project meta-data, e.g. to get the project name for the breadcrumbs
  useUpdateProjectMetadata(projectApiClient.projectId);

  const defaultStepKey = useLatestDataPreparationStep(revision.state);
  const steps = useDataPreparationSteps(revision.state);
  const [activeStepKey, setActiveStepKey] = useState(defaultStepKey);

  const dashboardUrl = useAppSelector(selectDashboardUrl);

  const scanEntities = useAppSelector(selectRevisionScans, isEqual);
  const pointCloudStreams = useLoadRevisionPointCloudStreams(scanEntities);

  return (
    <Stack
      sx={{
        height: "100%",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <SphereViewerToolHeaderBar
        toolName="Data Preparation"
        projectId={projectApiClient.projectId}
        exitHref={
          dashboardUrl &&
          getProjectPreparedDataPage(dashboardUrl, projectApiClient.projectId)
        }
      />
      <FaroStepper
        activeStepKey={activeStepKey}
        steps={steps}
        onStepChange={(step) => setActiveStepKey(step.key)}
      />
      <ThresholdSetProvider defaultThresholdSet={THRESHOLD_SET_STATIONARY}>
        {/* Display the view for the active step */}
        {activeStepKey === "registration" ? (
          <RegistrationView
            revisionState={revision.state}
            scanEntities={scanEntities}
            pointCloudStreams={pointCloudStreams}
          />
        ) : (
          <InspectAndPublishView
            revision={revision}
            scanEntities={scanEntities}
            pointCloudStreams={pointCloudStreams}
          />
        )}
      </ThresholdSetProvider>
    </Stack>
  );
}
