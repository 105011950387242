import { useAppSelector } from "@/store/store-hooks";
import {
  IElementGenericAnnotation,
  isIElementMarkup,
  isValid,
} from "@faro-lotv/ielement-types";
import { selectChildDepthFirst } from "@faro-lotv/project-source";
import { Stack } from "@mui/system";
import { AnnotationListCard } from "./annotation-list-card";

export type AnnotationListProps = {
  /** All annotations to show in the list */
  annotations?: IElementGenericAnnotation[];
};

/** @returns a list of annotations cards */
export function AnnotationList({
  annotations,
}: AnnotationListProps): JSX.Element {
  const markups = useAppSelector((state) =>
    annotations
      ?.map((el) => selectChildDepthFirst(el, isIElementMarkup)(state))
      .filter(isValid),
  );

  return (
    <Stack sx={{ overflow: "auto" }}>
      {markups?.map((annotation) => (
        <AnnotationListCard key={annotation.id} markup={annotation} />
      ))}
    </Stack>
  );
}
