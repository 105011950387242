import { useAppDispatch } from "@/store/store-hooks";
import { GUID } from "@faro-lotv/foundation";
import { useApiClientContext } from "@faro-lotv/service-wires";
import { useEffect, useState } from "react";
import {
  addRevisionEdges,
  isRegistrationEdgeMetricsData,
  RegistrationEdgeWithMetrics,
} from "../store/revision-slice";

/**
 * @param revisionId The ID of the revision to load the registration edges of.
 * @returns The registration edges of the given revision.
 * While the edges are loading, `undefined` is returned.
 */
export function useLoadRevisionEdges(
  revisionId: GUID,
): RegistrationEdgeWithMetrics[] | undefined {
  const dispatch = useAppDispatch();
  const { projectApiClient } = useApiClientContext();

  const [edges, setEdges] = useState<RegistrationEdgeWithMetrics[]>();

  useEffect(() => {
    const abortController = new AbortController();

    projectApiClient
      .getRegistrationEdgesForRevision(
        revisionId,
        isRegistrationEdgeMetricsData,
        abortController.signal,
      )
      .then((edges) => {
        // Add the edges to the store
        dispatch(addRevisionEdges(edges));
        // Set the return parameter to finish loading
        setEdges(edges);
      });

    return () => {
      abortController.abort("useLoadRegistrationRevisionEdges hook unmounted");
    };
  }, [projectApiClient, revisionId, dispatch]);

  return edges;
}
