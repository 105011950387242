import { Stack } from "@mui/system";
import { useState } from "react";
import { FilterMenuRow } from "./filter-menu-row";
import { FilterToggle } from "./filter-toggle";
import { SortMenuButton } from "./sort-menu-button";

/** @returns the panel to define the sort and filter settings for the annotation list */
export function FilterAndSortPanel(): JSX.Element {
  const [isFilterRowVisible, toggleFilters] = useState(false);

  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between">
        <FilterToggle
          isChecked={isFilterRowVisible}
          onToggle={toggleFilters}
          hasFilters={false}
        />
        <SortMenuButton />
      </Stack>
      <FilterMenuRow isVisible={isFilterRowVisible} />
    </Stack>
  );
}
