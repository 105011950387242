import { selectBackgroundTaskForIElement } from "@/store/background-tasks/background-tasks-selector";
import { useAppSelector } from "@/store/store-hooks";
import { selectHasWritePermission } from "@/store/user-selectors";
import {
  TreeData,
  selectChildDepthFirst,
  selectIElement,
  selectIsFloorWithoutScale,
  selectIsPointCloudAligned,
} from "@faro-lotv/app-component-toolbox";
import {
  IElementBase,
  IElementType,
  IElementTypeHint,
  isAreaAligned,
  isGeoReferencedElement,
  isIElementAreaSection,
  isIElementPointCloudStream,
} from "@faro-lotv/ielement-types";
import { BackgroundTaskState } from "@faro-lotv/service-wires";
import { AlignAreaButton } from "../../align-area-button";
import { AlignCloudButton } from "../../align-cloud-button";
import { SetFloorScaleButton } from "../../set-floor-scale-button";

type ProjectTreeActionProps = Pick<IElementBase, "id" | "type" | "typeHint">;

/** @returns the action button for the tree based on the current node */
export function CaptureTreeAction({
  id,
  type,
  typeHint,
}: ProjectTreeActionProps): JSX.Element | null {
  // Dispatch to the proper action based on the node
  if (type !== IElementType.section) return null;

  switch (typeHint) {
    case IElementTypeHint.dataSession:
      return <DataSessionAlignAction id={id} />;
    case IElementTypeHint.area:
      return <AreaSectionAction id={id} />;
    default:
      return null;
  }
}

function DataSessionAlignAction({
  id,
}: Pick<TreeData, "id">): JSX.Element | null {
  const hasWritePermission = useAppSelector(selectHasWritePermission);
  const pointCloud = useAppSelector(selectIElement(id));
  const isAligned = useAppSelector(selectIsPointCloudAligned(pointCloud));
  const isGeoReferenced = isGeoReferencedElement(pointCloud);
  const hasPcStream = useAppSelector(
    selectChildDepthFirst(pointCloud, isIElementPointCloudStream),
  );
  const isProcessing =
    useAppSelector(selectBackgroundTaskForIElement(id))?.state ===
    BackgroundTaskState.started;

  if (
    !hasWritePermission ||
    !pointCloud ||
    !hasPcStream ||
    isAligned ||
    isGeoReferenced ||
    isProcessing
  ) {
    return null;
  }

  return <AlignCloudButton pointCloud={pointCloud} />;
}

function AreaSectionAction({ id }: Pick<TreeData, "id">): JSX.Element | null {
  const section = useAppSelector(selectIElement(id));
  const withoutScale = useAppSelector(selectIsFloorWithoutScale(section));

  if (!section || !isIElementAreaSection(section)) {
    return null;
  }

  // We have 2 possible action for area element: "Set Scale" and "Align" but don't want to show both buttons
  // "Set Scale" defined as having higher priority. In case if there are both conditions we'll show only Set Scale"
  // then after resolving scaling issue "Align" button will be rendered
  if (withoutScale) {
    return <SetFloorScaleButton id={id} />;
  }

  if (isAreaAligned(section)) {
    return null;
  }

  return <AlignAreaButton area={section} tooltip="" allowUseAlignmentWizard />;
}
