import {
  DEFAULT_INITIAL_STATE,
  InitialStateProps,
  ModeWithSceneFilterInitialState,
} from "@/modes/mode";
import {
  useComputeValidModeElement,
  useCurrentArea,
} from "@/modes/mode-data-context";
import { selectWalkMainSceneType } from "@/store/modes/walk-mode-slice";
import { useAppStore } from "@/store/store-hooks";
import { SceneFilter } from "@/types/scene-filter";
import { GUID, includes, validatePrimitive } from "@faro-lotv/foundation";
import { selectIsElementViewable } from "../mode-selectors";
import { WalkSceneActiveElement, isWalkSceneActiveElement } from "./walk-types";

/**
 * Analyze the current scene and adjust it so it can work for walk mode
 * Makes sure the active element is one of the valid sessions for a walk scene
 *
 * @param fallback The function called if no valid model was found
 * @returns the main object walk mode can render
 */
export function useComputeValidWalkScene(
  fallback: (() => void) | undefined = undefined,
): WalkSceneActiveElement | undefined {
  const { dataSessions2d, dataSessions3d, dataSessions5d, roomsSections } =
    useCurrentArea();

  const { getState } = useAppStore();

  return useComputeValidModeElement(
    [...dataSessions2d, ...dataSessions3d, ...dataSessions5d, ...roomsSections],
    (el): el is WalkSceneActiveElement =>
      isWalkSceneActiveElement(el) && selectIsElementViewable(el)(getState()),
    fallback,
  );
}

/** An object to compute and parse a state object to create and restore the walk mode states from deep links */
export const WALK_MODE_INITIAL_STATE: InitialStateProps<ModeWithSceneFilterInitialState> =
  {
    compute(state, viewContext) {
      return {
        ...DEFAULT_INITIAL_STATE.compute(state, viewContext),
        scene: selectWalkMainSceneType(state),
      };
    },
    parse(appState, modeState) {
      let lookAtId: GUID | undefined;
      const scene = includes(Object.values(SceneFilter), modeState.scene)
        ? modeState.scene
        : SceneFilter.Pano;
      if (validatePrimitive(modeState, "lookAtId", "string")) {
        ({ lookAtId } = modeState);
      }
      return {
        ...DEFAULT_INITIAL_STATE.parse(appState, modeState),
        scene,
        lookAtId,
      };
    },
  };
