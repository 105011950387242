import { useCurrentSceneIfAvailable } from "@/modes/mode-data-context";
import { TextField } from "@faro-lotv/flat-ui";
import { Stack } from "@mui/system";
import { AnnotationList } from "./annotation-list";
import { FilterAndSortPanel } from "./filter-and-sort-panel";

/** @returns the Panel to search and interact with all the project annotations */
export function AnnotationPanel(): JSX.Element {
  // TODO: Replace with custom loading of all project annotations (https://faro01.atlassian.net/browse/SWEB-5343)
  const annotations = useCurrentSceneIfAvailable()?.annotations;

  return (
    <Stack sx={{ p: 1, m: 0, width: "100%", height: "100%" }}>
      <TextField placeholder="Search" fullWidth />
      <FilterAndSortPanel />
      <AnnotationList annotations={annotations} />
    </Stack>
  );
}
