import { useMapPlaceholderPositions } from "@/hooks/use-map-placeholder-positions";
import { useObjectBoundingBox } from "@/hooks/use-object-bounding-box";
import { useAppSelector } from "@/store/store-hooks";
import {
  ScreenSpaceOrthographicLineCollision,
  selectAncestor,
  selectChildrenDepthFirst,
} from "@faro-lotv/app-component-toolbox";
import { blue } from "@faro-lotv/flat-ui";
import {
  IElement,
  IElementGenericImgSheet,
  IElementImg360,
  IElementSection,
  isIElementImg360,
} from "@faro-lotv/ielement-types";
import { Line } from "@react-three/drei";
import { ThreeEvent } from "@react-three/fiber";
import { forwardRef, useCallback, useMemo, useState } from "react";
import mergeRefs from "react-merge-refs";
import {
  Box3,
  CatmullRomCurve3,
  Color,
  GreaterStencilFunc,
  Group,
  KeepStencilOp,
  ReplaceStencilOp,
  Vector3,
} from "three";
import { OdometryPathPlaceholders } from "./odometry-path-placeholders";

/** Line rendering parameters */
const POINTS_SEGMENT_FACTOR = 50;
const LINE_EVENT_WIDTH = 50;
const LINE_WIDTH = 8;
const LINE_BORDER_WIDTH = LINE_WIDTH + 3;
const LINE_OPACITY = 0.8;

/** Offsets applied to the line to avoid z-fighting */
const LINE_HEIGHT_OFFSET = 0.1;
const LINE_HEIGHT_OFFSET_ACTIVE = 0.11;

/** Name to assign to a odometry path object/group so it can be recognized by the rendering pipeline */
export const ODOMETRY_PATH_OBJECT_NAME = "OdometryPath";

type OdometryPathRendererProps = Pick<PathRendererProps, "stencilRef"> & {
  /** The section with typeHint "OdometryPath" to render */
  path: IElementSection;

  /** The sheet to render the paths for */
  sheet?: IElementGenericImgSheet;

  /** The current active video recording */
  activeRecording?: IElement;

  /** Callback when the path is clicked */
  onPathClick?(
    event: ThreeEvent<MouseEvent>,
    path: IElementSection,
    pathBbox: Box3,
  ): void;

  /** Callback when a pano placeholder is clicked */
  onPlaceholderClick(pano: IElementImg360): void;

  /** Callback when a pano placeholder is hovered */
  onPlaceholderHovered(pano?: IElementImg360): void;
};

/** @returns a single interactive odometry path  */
export function OdometryPathRenderer({
  path,
  sheet,
  activeRecording,
  stencilRef,
  onPathClick,
  onPlaceholderClick: onPanoClicked,
  onPlaceholderHovered: onPanoHovered,
}: OdometryPathRendererProps): JSX.Element | null {
  const panos = useAppSelector(
    selectChildrenDepthFirst(path, isIElementImg360),
  );
  const positions = useMapPlaceholderPositions(panos, sheet);

  const onClick = useCallback(
    (event: ThreeEvent<MouseEvent>, pathBbox: Box3) => {
      onPathClick?.(event, path, pathBbox);
    },
    [onPathClick, path],
  );

  const onPlaceholderClick = useCallback(
    (index: number) => {
      onPanoClicked(panos[index]);
    },
    [onPanoClicked, panos],
  );

  const onPlaceholderHovered = useCallback(
    (index?: number) =>
      onPanoHovered(index === undefined ? undefined : panos[index]),
    [onPanoHovered, panos],
  );

  const isActive = !!useAppSelector((state) =>
    selectAncestor(path, (e) => e === activeRecording)(state),
  );

  if (panos.length === 0) return null;

  return (
    <PathRenderer
      positions={positions}
      isActive={isActive}
      stencilRef={stencilRef}
      onClick={onClick}
      onPlaceholderClick={onPlaceholderClick}
      onHoveredPlaceholderChanged={onPlaceholderHovered}
    />
  );
}

export type PathRendererProps = {
  /** List of positions of the placeholders describing the path */
  positions: Vector3[];

  /** For each placeholder, a flag specifying if is locked */
  locked?: boolean[];

  /**
   * Number of intermediate point between two placeholders when drawing the curve
   *
   * @default 50
   */
  segmentFactor?: number;

  /** Whether to show the path in its active state  */
  isActive: boolean;

  /**
   * The value used in the stencil buffer to draw the background line
   *
   * @default 1
   */
  stencilRef?: number;

  /** Callback when the path is clicked */
  onClick?(event: ThreeEvent<MouseEvent>, pathBbox: Box3): void;

  /** Callback when a placeholder is clicked */
  onPlaceholderClick?(index: number): void;

  /** Callback when a placeholder is hovered */
  onHoveredPlaceholderChanged?(index: number | undefined): void;
};

/** @returns a single interactive odometry path  */
export const PathRenderer = forwardRef<Group, PathRendererProps>(
  function PathRenderer(
    {
      positions,
      locked = [],
      segmentFactor = POINTS_SEGMENT_FACTOR,
      isActive,
      stencilRef = 1,
      onClick,
      onPlaceholderClick,
      onHoveredPlaceholderChanged,
    }: PathRendererProps,
    ref,
  ): JSX.Element | null {
    const [isHovered, setIsHovered] = useState(false);
    const [hoveredId, setHoveredId] = useState<number>();
    const [pathObject, setPathObject] = useState<Group | null>(null);

    const pathBbox = useObjectBoundingBox(pathObject);

    const pathColor = useMemo(
      () =>
        isActive || isHovered ? new Color(blue[500]) : new Color(blue[400]),
      [isHovered, isActive],
    );

    // Use the first point as the trajectory position,
    const trajectoryPosition = positions[0];

    // offset all points by trajectoryPosition and create new points and line segments
    const { points, segments } = useMemo(() => {
      const points = positions.map((p) => {
        return p.clone().sub(trajectoryPosition);
      });

      const segments =
        points.length > 1
          ? new CatmullRomCurve3(points).getPoints(
              points.length * segmentFactor,
            )
          : points;
      return { points, segments };
    }, [positions, segmentFactor, trajectoryPosition]);

    const onPlaceholderHovered = useCallback(
      (id?: number) => {
        setHoveredId(id);
        onHoveredPlaceholderChanged?.(id);
      },
      [onHoveredPlaceholderChanged],
    );

    const realHovered = isHovered || hoveredId !== undefined;

    if (positions.length === 0) return null;

    return (
      <group
        ref={mergeRefs([ref, setPathObject])}
        name={ODOMETRY_PATH_OBJECT_NAME}
        position-x={trajectoryPosition.x}
        position-z={trajectoryPosition.z}
        position-y={
          trajectoryPosition.y +
          (isActive ? LINE_HEIGHT_OFFSET_ACTIVE : LINE_HEIGHT_OFFSET)
        }
      >
        {segments.length > 2 && (
          <ScreenSpaceOrthographicLineCollision
            points={segments}
            lineWidth={LINE_EVENT_WIDTH}
            onPointerEnter={() => setIsHovered(true)}
            onPointerLeave={() => setIsHovered(false)}
            onClick={(ev) =>
              onClick
                ? ev.delta <= 1 && onClick(ev, pathBbox ?? new Box3())
                : undefined
            }
          />
        )}
        <Line
          forceSinglePass={false}
          points={segments}
          lineWidth={LINE_BORDER_WIDTH}
          transparent
          color="white"
          depthTest={false}
          stencilRef={stencilRef}
          stencilFunc={GreaterStencilFunc}
          stencilFail={KeepStencilOp}
          stencilZPass={ReplaceStencilOp}
          stencilWrite
        />
        <group position-y={0.1}>
          <Line
            forceSinglePass={false}
            points={segments}
            lineWidth={LINE_WIDTH}
            transparent
            opacity={LINE_OPACITY}
            color={pathColor}
            depthTest={false}
            stencilRef={stencilRef + 1}
            stencilFunc={GreaterStencilFunc}
            stencilFail={KeepStencilOp}
            stencilZPass={ReplaceStencilOp}
            stencilWrite
          />
          <OdometryPathPlaceholders
            positions={points}
            locked={locked}
            showAll={realHovered || isActive}
            hoveredId={hoveredId}
            onHoveredPlaceholderChanged={onPlaceholderHovered}
            onPlaceholderClick={onPlaceholderClick}
          />
        </group>
      </group>
    );
  },
);
