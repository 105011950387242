import {
  CreateAnnotationShareLinkEventProperties,
  EventType,
  OpenAnnotationAttachmentEventProperties,
  OpenAnnotationDetailsContextMenuEventProperties,
} from "@/analytics/analytics-events";
import { MeasurementValuesField } from "@/components/r3f/renderers/annotations/annotation-renderers/measurement-values-field";
import { HandleAnnotationViewerVariantReturn } from "@/components/r3f/renderers/annotations/annotation-renderers/use-handle-annotation-details-variant";
import { EditAnnotation } from "@/components/ui/annotations/edit-annotation";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { useAnnotationAssignees } from "@/hooks/use-annotation-assignees";
import { useAnnotationPermissions } from "@/hooks/use-annotation-permissions";
import { useShareAnnotation } from "@/hooks/use-share-annotation";
import {
  selectProjectUser,
  selectProjectUsers,
} from "@/store/project-selector";
import { selectMarkupAttachments } from "@/store/selections-selectors";
import { useAppSelector } from "@/store/store-hooks";
import { selectCurrentUser } from "@/store/user-selectors";
import {
  getExternalMarkupProviderName,
  getMarkupStatusDisplayName,
} from "@faro-lotv/app-component-toolbox";
import { AnnotationViewer, FileDetails, UrlLink } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import {
  IElementMarkup,
  isIElementAttachment,
  isIElementMarkupDueTime,
  isIElementMarkupState,
  isIElementMeasurePolygon,
  isIElementUrlLink,
} from "@faro-lotv/ielement-types";
import {
  selectAncestor,
  selectChildDepthFirst,
  selectChildrenDepthFirst,
  selectExternalSyncedMarkupFor,
} from "@faro-lotv/project-source";
import { Box, Dialog, Stack, SxProps, Theme } from "@mui/material";
import { isEqual } from "lodash";
import { useCallback, useMemo, useState } from "react";

interface MarkupAnnotationUIProps
  extends Omit<
    HandleAnnotationViewerVariantReturn,
    "onAnnotationViewerHovered"
  > {
  /** The details about a specific annotation */
  markup: IElementMarkup;

  /** Optional icon to render next to the UI. Used for single point annotations */
  icon?: JSX.Element;

  /** Pixel size to use for the icon */
  iconSize?: number;

  /** Optional style to apply to the Markup's UI */
  sx?: SxProps<Theme>;

  /** Callback executed when the copy to clipboard button is pressed */
  onCopyToClipboard?(): void;

  /** Callback executed when the user requests to delete the annotation. */
  onDelete(): void;
}

/** @returns the ui to interact with an annotation */
export function MarkupAnnotationUI({
  markup,
  icon,
  iconSize,
  sx,
  AnnotationViewerVariant,
  onAnnotationViewerExpanded,
  onAnnotationViewerClosed,
  onCopyToClipboard,
  onDelete,
}: MarkupAnnotationUIProps): JSX.Element | null {
  // Check if the markup has an attachment
  const hasAttachment = useAppSelector(
    (state) => selectMarkupAttachments(markup)(state).length > 0,
  );

  const { handleErrorWithToast } = useErrorHandlers();

  // Collect the info related to this annotation
  const dueDate = useAppSelector(
    selectChildDepthFirst(markup, isIElementMarkupDueTime),
  );
  const state = useAppSelector(
    selectChildDepthFirst(markup, isIElementMarkupState),
  );
  const mainAssignee = useAnnotationAssignees(markup).at(0);
  const projectUsers = useAppSelector(selectProjectUsers);

  // The creator of the annotation
  const createdBy = useAppSelector((state) =>
    selectCurrentUser(state)?.id === markup.createdBy
      ? selectCurrentUser(state)
      : selectProjectUser(markup.createdBy)(state),
  );

  // For now use the first assignee
  const assigneeId = useMemo(
    () =>
      mainAssignee
        ? projectUsers.find((user) => user.id === mainAssignee.id)?.id
        : undefined,
    [mainAssignee, projectUsers],
  );

  const { canWriteAnnotations } = useAnnotationPermissions();
  const [isEditing, setIsEditing] = useState(false);

  const shareAnnotation = useShareAnnotation(markup.id);

  const measurement = useAppSelector(
    selectAncestor(markup, isIElementMeasurePolygon),
  );
  const isMeasurement = !!measurement;

  const attachmentsElements = useAppSelector(
    selectChildrenDepthFirst(markup, isIElementAttachment),
    isEqual,
  );

  const externalMarkup = useAppSelector(selectExternalSyncedMarkupFor(markup));

  const attachments = useMemo<FileDetails[]>(
    () =>
      attachmentsElements.map((el) => ({
        id: el.id,
        fileName: el.name,
        fileSize: el.fileSize,
        date: el.createdAt,
        urlOrFile: el.uri,
        thumbnailUrl: el.thumbnailUri,
      })),
    [attachmentsElements],
  );

  const trackContextMenuOpening = useCallback(() => {
    Analytics.track<OpenAnnotationDetailsContextMenuEventProperties>(
      EventType.openAnnotationDetailsContextMenu,
      {
        via: AnnotationViewerVariant,
      },
    );
  }, [AnnotationViewerVariant]);

  const linkElements = useAppSelector(
    selectChildrenDepthFirst(markup, isIElementUrlLink),
    isEqual,
  );
  const links = useMemo<UrlLink[] | undefined>(() => {
    if (linkElements.length === 0) return;
    return linkElements.map((el) => ({
      label: el.descr ?? el.name,
      href: el.uri,
    }));
  }, [linkElements]);

  const tags = useMemo(
    () => markup.labels?.map((l) => ({ id: l.id, label: l.name })),
    [markup.labels],
  );

  const isFull = AnnotationViewerVariant === "full";

  const onIconClicked = useCallback(() => {
    if (isFull) {
      onAnnotationViewerClosed();
    } else {
      onAnnotationViewerExpanded();
    }
  }, [isFull, onAnnotationViewerClosed, onAnnotationViewerExpanded]);

  const shouldRenderDetails =
    AnnotationViewerVariant !== "hidden" &&
    AnnotationViewerVariant !== "collapsed";

  return (
    <>
      <Stack
        direction="row"
        alignItems="top"
        sx={{
          width: "max-content",
          ...sx,
        }}
      >
        <Box
          component="div"
          sx={{
            width: iconSize,
            height: iconSize,
            visibility: icon ? "visible" : "collapse",
            cursor: "pointer",
          }}
          onClick={onIconClicked}
        >
          {icon}
        </Box>
        {shouldRenderDetails && (
          <AnnotationViewer
            onDescriptionError={(error) =>
              handleErrorWithToast({ title: "Error in description", error })
            }
            onExpandButtonClick={() => {
              Analytics.track(EventType.expandAnnotationDetails);
              onAnnotationViewerExpanded();
            }}
            onCloseButtonClick={() => {
              Analytics.track(EventType.closeAnnotationDetails);
              onAnnotationViewerClosed();
            }}
            onShareButtonClick={() => {
              Analytics.track<CreateAnnotationShareLinkEventProperties>(
                EventType.createAnnotationShareLink,
                {
                  via: AnnotationViewerVariant,
                },
              );

              shareAnnotation();
            }}
            onAttachmentOpened={(fileType: string) =>
              Analytics.track<OpenAnnotationAttachmentEventProperties>(
                EventType.openAnnotationAttachment,
                {
                  fileType,
                },
              )
            }
            // External markups are not supported yet
            canEdit={canWriteAnnotations && !externalMarkup}
            onContextMenuOpen={trackContextMenuOpening}
            onEdit={() => {
              Analytics.track(EventType.editAnnotation);
              setIsEditing(true);
            }}
            onDelete={onDelete}
            variant={AnnotationViewerVariant}
            title={markup.name}
            assignee={mainAssignee}
            createdBy={createdBy}
            dueDate={dueDate ? new Date(dueDate.value) : undefined}
            description={markup.descr}
            status={getMarkupStatusDisplayName(state?.value)}
            syncService={getExternalMarkupProviderName(externalMarkup)}
            hasAttachment={hasAttachment}
            isMeasurement={isMeasurement}
            onCopyToClipboard={onCopyToClipboard}
            files={attachments}
            links={links}
            tags={tags}
          >
            {measurement && (
              <MeasurementValuesField measurement={measurement} />
            )}
          </AnnotationViewer>
        )}
      </Stack>
      {isEditing && (
        <Dialog
          open
          PaperProps={{
            sx: {
              padding: 0,
              backgroundColor: "transparent",
              overflow: "hidden",
              boxShadow: "none",
            },
          }}
        >
          <EditAnnotation
            iElement={markup}
            title={markup.name}
            description={markup.descr ?? undefined}
            assignee={assigneeId}
            status={state?.value ?? undefined}
            dueDate={dueDate?.value ? new Date(dueDate.value) : undefined}
            onClose={() => setIsEditing(false)}
          >
            {measurement && (
              <MeasurementValuesField measurement={measurement} />
            )}
          </EditAnnotation>
        </Dialog>
      )}
    </>
  );
}
