import { EventType } from "@/alignment-tool/analytics/analytics-events";
import { AlignmentStepper } from "@/modes/alignment-modes-commons/alignment-stepper";
import { useAppDispatch } from "@/store/store-hooks";
import { FaroStep } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { useMemo } from "react";
import { CloudToSheetAlignmentStepNames } from "../alignment-steps/steps";
import { setAlignmentStep } from "../store/alignment-slice";

interface CloudToSheetAlignmentProgressBar {
  /** Method to call when the user confirms the alignment */
  onFinishAlignment(): void;
}

/**
 * @returns The bar that is shown in the top of the app
 *  Allows user to see where there are currently in the alignment process, and switch between those steps
 */
export function CloudToSheetAlignmentProgressBar({
  onFinishAlignment,
}: CloudToSheetAlignmentProgressBar): JSX.Element {
  const dispatch = useAppDispatch();

  /** defines steps for CloudToSheet alignment progressbar */
  const steps: FaroStep[] = useMemo(
    () => [
      {
        key: CloudToSheetAlignmentStepNames.alignIn2d,
        label: "Align",
        onBeforeNext: () => {
          Analytics.track(EventType.alignmentNextStep, {
            currentStep: CloudToSheetAlignmentStepNames.alignIn2d,
          });

          dispatch(
            setAlignmentStep(CloudToSheetAlignmentStepNames.setElevation),
          );
          return Promise.resolve();
        },
      },
      {
        key: CloudToSheetAlignmentStepNames.setElevation,
        label: "Elevation",
        onBeforeBack: () => {
          Analytics.track(EventType.alignmentPreviousStep, {
            currentStep: CloudToSheetAlignmentStepNames.setElevation,
          });

          dispatch(setAlignmentStep(CloudToSheetAlignmentStepNames.alignIn2d));
          return Promise.resolve();
        },
      },
    ],
    [dispatch],
  );

  return (
    <AlignmentStepper
      steps={steps}
      lastStepButtonText="Confirm Alignment"
      // TODO - those absolute offsets will be addressed in https://faro01.atlassian.net/browse/CADBIM-772
      sideMargin={0}
      topMargin={0}
      onLastStepButtonClicked={() => {
        Analytics.track(EventType.alignmentConfirmed);
        onFinishAlignment();
      }}
    />
  );
}
