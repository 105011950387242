import {
  setSheetToCadAlignmentStep,
  SheetToCadAlignmentStep,
} from "@/store/modes/sheet-to-cad-alignment-mode-slice";
import { useAppDispatch } from "@/store/store-hooks";
import { FaroStep } from "@faro-lotv/flat-ui";
import { useMemo } from "react";
import { AlignmentStepper } from "../alignment-modes-commons/alignment-stepper";

interface CadAlignmentProgressBarProps {
  /** A callback that is called when the user wants to apply the alignment */
  apply(): void;
}

/**
 * @returns The bar that is shown in the top of a sheet-to-cad alignment mode
 *  Allows user to see where there are currently in the alignment process, and switch between those steps
 */
export function SheetToCadAlignmentProgressBar({
  apply,
}: CadAlignmentProgressBarProps): JSX.Element {
  const dispatch = useAppDispatch();

  /** defines steps for SheetToCad Alignment progressBar */
  const steps: FaroStep[] = useMemo(
    () => [
      {
        key: SheetToCadAlignmentStep.setElevation,
        label: "Elevation",
        onBeforeNext: () => {
          dispatch(
            setSheetToCadAlignmentStep(SheetToCadAlignmentStep.alignSheet),
          );
          return Promise.resolve();
        },
      },
      {
        key: SheetToCadAlignmentStep.alignSheet,
        label: "Align",
        onBeforeBack: () => {
          dispatch(
            setSheetToCadAlignmentStep(SheetToCadAlignmentStep.setElevation),
          );
          return Promise.resolve();
        },
      },
    ],
    [dispatch],
  );

  return (
    <AlignmentStepper
      steps={steps}
      lastStepButtonText="Confirm Alignment"
      onLastStepButtonClicked={apply}
    />
  );
}
