import { useAppSelector } from "@/store/store-hooks";
import {
  FaroDialog,
  FaroText,
  PointCloudSmallIcon,
  neutral,
} from "@faro-lotv/flat-ui";
import { GUID } from "@faro-lotv/foundation";
import { selectIElement } from "@faro-lotv/project-source";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Divider, ListItemButton, Stack, SvgIcon } from "@mui/material";
import { useState } from "react";
import { ReactComponent as Connection } from "../../icons/connections.svg";
import { ReactComponent as RegistrationConnections } from "../../icons/metrics-connections-graphic.svg";
import { RegistrationDetails } from "../../utils/metrics";
import {
  Cluster,
  MultiRegistrationReport,
  Registration,
  Scan,
  validateCluster,
} from "../../utils/multi-registration-report";
import { MultiCloudRegistrationMetrics } from "./multicloud-registration-metrics";
import { RegistrationCard } from "./registration-card";
import { QualityIcon, RegistrationMetrics } from "./registration-metrics";
import { useThresholdSetContext } from "./threshold-set-context";
import { ThresholdSetSwitch } from "./threshold-set-switch";

/**
 * @returns A view displaying the report for a multi-cloud registration.
 */
export function MultiCloudRegistrationReportView({
  scanMetrics,
  scans,
}: MultiRegistrationReport): JSX.Element {
  return (
    <Stack alignItems="center" spacing={1}>
      <ThresholdSetSwitch />
      <MultiCloudRegistrationMetrics {...scanMetrics} />
      <RegistrationCard
        title="Connections"
        cardIcon={
          <SvgIcon
            component={RegistrationConnections}
            inheritViewBox
            sx={{ width: "100%", height: "100%" }}
          />
        }
      >
        <FaroText variant="helpText">
          Here you can see how many and which connections with other point
          clouds were calculated for each point cloud. Click on a connection to
          see the details page for each pair.
        </FaroText>
        <Stack divider={<Divider flexItem />}>
          {scans.children.map((child) => (
            <ChildView child={child} key={child.uuid} />
          ))}
        </Stack>
      </RegistrationCard>
    </Stack>
  );
}

type ChildViewProps = {
  /** The scan or cluster to display. */
  child: Scan | Cluster;
};

/**
 * @returns A view for a cluster or scan report.
 */
function ChildView({ child }: ChildViewProps): JSX.Element {
  if (validateCluster(child)) {
    return <ClusterView {...child} />;
  }

  return <ScanView {...child} />;
}

/**
 * @returns View for registrations result of a cluster.
 */
function ClusterView({ children }: Cluster): JSX.Element {
  // For now just spread in all children, until we have a design for how to handle clusters
  return (
    <>
      {children.map((child) => {
        <ChildView child={child} key={child.uuid} />;
      })}
    </>
  );
}

/**
 * @returns View for registrations result of a scan.
 */
function ScanView({ uuid, registrations }: Scan): JSX.Element {
  // TODO: Use name provided by the backend data instead
  // Currently this is the ID of the element instead
  const name = useElementName(uuid);

  return (
    <Stack gap={0.5}>
      <Stack
        direction="row"
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <Stack direction="row" columnGap={1} alignItems="flex-end">
          <SvgIcon
            component={PointCloudSmallIcon}
            inheritViewBox
            sx={{ width: "1em", height: "1em" }}
          />
          <FaroText variant="heading14">{name}</FaroText>
        </Stack>
        <FaroText
          variant="heading14"
          sx={{ color: "info.main" }}
          padding="0.5em"
        >
          {registrations.length}
          {registrations.length === 1 ? " Connection" : " Connections"}
        </FaroText>
      </Stack>
      <Stack direction="column" pl={3}>
        {registrations.map((registration) => (
          <RegistrationView
            {...registration}
            sourceScanName={name}
            key={registration.registrationObjectId}
          />
        ))}
      </Stack>
    </Stack>
  );
}

type RegistrationViewProps = Registration & {
  /** The name of the source scan in the registration connection. */
  sourceScanName: string;
};

/**
 * @returns View for a single cloud-to-cloud registration, i.e. the connection between two scans.
 */
function RegistrationView({
  targetScanId,
  metrics,
  sourceScanName,
}: RegistrationViewProps): JSX.Element {
  // TODO: Use name provided by the backend data instead, once implemented
  const targetScanName = useElementName(targetScanId);
  const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState(false);

  const { thresholdSet } = useThresholdSetContext();

  return (
    <>
      <ListItemButton
        title="View connection details"
        onClick={() => setIsDetailsDialogOpen(true)}
        sx={{ px: 1, py: 0.5 }}
      >
        <Stack
          direction="row"
          columnGap={1}
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Stack direction="row" alignItems="center" columnGap={1}>
            <SvgIcon
              component={Connection}
              inheritViewBox
              sx={{
                width: "1em",
                height: "1em",
                color: neutral[400],
              }}
            />
            <FaroText variant="heading14" color={neutral[600]}>
              {targetScanName}
            </FaroText>
          </Stack>
          <Stack direction="row" alignItems="center" gap={1}>
            <QualityIcon
              quality={
                new RegistrationDetails(
                  metrics.overlap,
                  metrics.rlyHistogram,
                  thresholdSet,
                ).registrationQuality
              }
              width="0.75em"
              height="0.75em"
            />
            <KeyboardArrowRightIcon style={{ fill: neutral[400] }} />
          </Stack>
        </Stack>
      </ListItemButton>
      <FaroDialog
        title={`Quality View: ${sourceScanName} – ${targetScanName}`}
        open={isDetailsDialogOpen}
        onClose={() => setIsDetailsDialogOpen(false)}
        size="l"
      >
        <RegistrationMetrics
          overlap={metrics.overlap}
          rlyHistogram={metrics.rlyHistogram}
          thresholdSet={thresholdSet}
        />
      </FaroDialog>
    </>
  );
}

/**
 * @param id The ID of the iElement to get the name of.
 * @returns The name of the IElement, or the ID if the name or element is missing.
 */
function useElementName(id: GUID): string {
  const iElement = useAppSelector(selectIElement(id));
  return iElement?.name ?? id;
}
