import { useElementFileUploadContext } from "@/components/common/point-cloud-file-upload-context/element-file-upload-context";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectCurrentUser } from "@/store/user-selectors";
import { VolumeInfo } from "@/utils/volume-utils";
import { generateGUID } from "@faro-lotv/foundation";
import {
  IElement,
  IElementType,
  IElementTypeHint,
  isIElementWithTypeAndHint,
} from "@faro-lotv/ielement-types";
import {
  fetchProjectIElements,
  selectChildDepthFirst,
  selectProjectId,
  selectRootIElement,
} from "@faro-lotv/project-source";
import {
  createMutationAddArea,
  createMutationAddVolume,
  createMutationSetAreaWorldPose,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { useCallback } from "react";

/**
 * Hook that creates an area, a volume and a sheet in the project
 *
 * @param areaName Name of the area
 * @param volume Volume of the area
 * @returns a function that creates the area and the volume.
 * The function needs to be called passing a canvas so that the sheet can be generated
 */
export function useCreateAreaAndVolume(
  areaName: string,
  volume?: VolumeInfo,
): (canvas: HTMLCanvasElement) => Promise<void> {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectCurrentUser);
  const root = useAppSelector(selectRootIElement);
  const projectId = useAppSelector(selectProjectId);

  const { uploadAreaImageFile } = useElementFileUploadContext();
  const { projectApiClient } = useApiClientContext();

  const slideContainer = useAppSelector(
    selectChildDepthFirst(root, (iElement: IElement) =>
      isIElementWithTypeAndHint(
        iElement,
        IElementType.group,
        IElementTypeHint.slideContainer,
      ),
    ),
  );

  // This will create a new area in the project
  // update its pose and then
  // create an overview image and a volume
  return useCallback(
    async (canvas: HTMLCanvasElement) => {
      if (!projectId || !slideContainer || !volume) return;

      // Create a new area in the project
      const area = generateGUID();

      await projectApiClient.applyMutations([
        createMutationAddArea({
          id: area,
          rootId: slideContainer.rootId,
          groupId: slideContainer.id,
          name: areaName,
        }),
        createMutationSetAreaWorldPose(
          area,
          1,
          volume.rotation,
          volume.position,
        ),
      ]);

      // Fetch the changed sub-tree and update the local copy of the project
      await dispatch(
        fetchProjectIElements({
          // eslint-disable-next-line require-await -- FIXME
          fetcher: async () =>
            projectApiClient.getAllIElements({
              ancestorIds: [slideContainer.id],
            }),
        }),
      );

      if (area) {
        await projectApiClient.applyMutations([
          createMutationAddVolume({
            areaId: area,
            rootId: slideContainer.rootId,
            size: volume.size ?? { x: 1, y: 1, z: 1 },
            userId: user?.id,
          }),
        ]);

        // Get the content of the canvas as a blob
        canvas.toBlob((blob) => {
          if (!blob) return;
          // Create a file from the blob so that it can be uploaded to the backend
          const imageFile = new File([blob], `${areaName}.png`, {
            type: "image/png",
          });

          // Upload the image file to the backend and create the overview image in the project
          uploadAreaImageFile(areaName, area, imageFile);
        });
      }
    },
    [
      projectId,
      slideContainer,
      volume,
      projectApiClient,
      areaName,
      dispatch,
      user?.id,
      uploadAreaImageFile,
    ],
  );
}
