import { assert } from "@faro-lotv/foundation";
import { Matrix3, Matrix4, Matrix4Tuple, Plane, Vector3 } from "three";

// Given three orthogonal planes, return their unique point of intersection
// This function assumes that the three planes are orthogonal to each other.
function vertIntersectPlanes(p1: Plane, p2: Plane, p3: Plane): Vector3 {
  const n1 = p1.normal;
  const n2 = p2.normal;
  const n3 = p3.normal;
  const f1 = new Vector3().crossVectors(n2, n3).multiplyScalar(-p1.constant);
  const f2 = new Vector3().crossVectors(n3, n1).multiplyScalar(-p2.constant);
  const f3 = new Vector3().crossVectors(n1, n2).multiplyScalar(-p3.constant);
  const det = new Matrix3()
    .set(n1.x, n1.y, n1.z, n2.x, n2.y, n2.z, n3.x, n3.y, n3.z)
    .determinant();
  const vectorSum = new Vector3().add(f1).add(f2).add(f3);

  // Since the planes are orthographic, we can safely assume that the
  // determinant will be different from 0
  assert(
    Math.abs(det) > 0,
    "Planes forming the clipping box should be perpendicular to each other",
  );
  const planeIntersection = new Vector3(
    vectorSum.x / det,
    vectorSum.y / det,
    vectorSum.z / det,
  );
  return planeIntersection;
}

/**
 * @returns The matrix that transforms the unitary axis aligned bounding box ([0,0,0]-[1,1,1])
 * to the object oriented bounding box described by a list of six planes
 * @param planes The six planes describing the object oriented bounding box
 */
export function planesToArray(planes: Plane[]): Matrix4Tuple {
  // Find the translation. We are assuming that pl[1], pl[3], pl[5] are orthogonal
  const t0: Vector3 = vertIntersectPlanes(planes[1], planes[3], planes[5]);
  const t1: Vector3 = vertIntersectPlanes(planes[0], planes[2], planes[4]);
  const d = new Vector3().subVectors(t1, t0);

  // Verify whether the planes form a right-handed system
  const sign = new Vector3()
    .copy(planes[1].normal)
    .dot(new Vector3().crossVectors(planes[3].normal, planes[5].normal));

  // Project the diagonal of the generic box into its three directions
  const v0 = new Vector3()
    .copy(planes[1].normal)
    .multiplyScalar(d.dot(planes[1].normal));
  const v1 = new Vector3()
    .copy(planes[3].normal)
    .multiplyScalar(d.dot(planes[3].normal));
  const v2 = new Vector3()
    .copy(planes[5].normal)
    .multiplyScalar(d.dot(planes[5].normal));

  // Create transformation that brings the unit box into the generic one
  const translation = new Matrix4().makeTranslation(t0.x, t0.y, t0.z);
  const basis = new Matrix4().makeBasis(
    v0,
    sign < 0 ? v2 : v1,
    sign < 0 ? v1 : v2,
  );

  const trafo = new Matrix4().multiplyMatrices(translation, basis).transpose();
  return trafo.toArray();
}
