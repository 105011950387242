import {
  EventType,
  ShowPairwiseRegistrationReportEventProperties,
} from "@/analytics/analytics-events";
import { useAlignmentOverlay } from "@/registration-tools/common/alignment-overlay-context";
import { useRegistrationContext } from "@/registration-tools/common/registration-context";
import {
  selectIsRegistrationInProgress,
  selectRegistrationJobId,
} from "@/registration-tools/common/store/registration-selectors";
import {
  resetRegistrationJob,
  setLastRegistrationPose,
  setPointCloudTransform,
} from "@/registration-tools/common/store/registration-slice";
import { useRegistrationResult } from "@/registration-tools/common/use-registration-result";
import {
  RegistrationDetails,
  RegistrationMetricsData,
  getMetricScaleIcon,
} from "@/registration-tools/utils/metrics";
import {
  getModelWorldTransform,
  selectRegistrationTask,
} from "@/registration-tools/utils/registration";
import {
  getRegistrationFeedbackOnError,
  getRegistrationFeedbackOnFail,
  getRegistrationFeedbackOnSuccess,
} from "@/registration-tools/utils/registration-feedback";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { useTypedEvent } from "@faro-lotv/app-component-toolbox";
import { CircularProgress, FaroDialog, useToast } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { TypedEvent } from "@faro-lotv/foundation";
import {
  IElementGenericPointCloudStream,
  IPose,
  validatePose,
} from "@faro-lotv/ielement-types";
import { BackgroundTaskState } from "@faro-lotv/service-wires";
import { Backdrop } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { RegistrationMetrics } from "../common/registration-report/registration-metrics";
import { useThresholdSetContext } from "../common/registration-report/threshold-set-context";

type PairwiseRegistrationTaskTrackerProps = {
  /** The reference point cloud */
  activeRefPointCloud: IElementGenericPointCloudStream;
};

/**
 * @returns Component that renders a backdrop if a job is active and applies the result to the model pointcloud.
 * Provides visual feedback on job success or failure
 */
export function PairwiseRegistrationTaskTracker({
  activeRefPointCloud,
}: PairwiseRegistrationTaskTrackerProps): JSX.Element {
  const dispatch = useAppDispatch();
  const { getState } = useAppStore();

  const { thresholdSet } = useThresholdSetContext();

  const regJobid = useAppSelector(selectRegistrationJobId);

  const isRegistrationInProgress = useAppSelector(
    selectIsRegistrationInProgress,
  );
  const registrationTask = useAppSelector(selectRegistrationTask(regJobid));
  const registrationResult = useRegistrationResult(registrationTask);
  const { openToast } = useToast();

  const [regMetrics, setRegMetrics] = useState<
    RegistrationMetricsData | undefined
  >();
  const [isRegistrationResultDialogOpen, setIsRegistrationResultDialogOpen] =
    useState(false);
  const { showRegistrationResultsDialog } = useAlignmentOverlay();

  const { registrationQuality, setRegistrationQuality, registrationCompleted } =
    useRegistrationContext();

  // Sets callback function in the ManualAlignmentOverlay context.
  useTypedEvent<void, TypedEvent<void>>(showRegistrationResultsDialog, () =>
    setIsRegistrationResultDialogOpen(!isRegistrationResultDialogOpen),
  );

  // update the transform received from backend to be applied in the viewer
  const updateTransform = useCallback(
    (rawPose: IPose) => {
      const modelWorldTransform = getModelWorldTransform(
        rawPose,
        activeRefPointCloud,
        getState(),
      );
      dispatch(setPointCloudTransform(modelWorldTransform.toArray()));
      dispatch(setLastRegistrationPose(rawPose));
      // After the point cloud is transformed we remove the registration job id to signal that there is no active job anymore
      dispatch(resetRegistrationJob());
    },
    [activeRefPointCloud, dispatch, getState],
  );

  // Check result and apply it if valid
  useEffect(() => {
    // when task is in progress or failed
    if (!registrationResult) {
      if (registrationTask?.state === BackgroundTaskState.failed) {
        dispatch(resetRegistrationJob());
        openToast(getRegistrationFeedbackOnFail());
      }
      return;
    }
    // update the metrics from the registration result
    setRegMetrics(registrationResult.metrics);

    // set the registration quality
    const newRegistrationQuality = new RegistrationDetails(
      registrationResult.metrics.overlap,
      registrationResult.metrics.rlyHistogram,
      thresholdSet,
    ).registrationQuality;
    setRegistrationQuality(newRegistrationQuality);

    // send feedback on successful registration completion
    openToast(
      getRegistrationFeedbackOnSuccess(
        getMetricScaleIcon(newRegistrationQuality),
        newRegistrationQuality,
        () => {
          Analytics.track<ShowPairwiseRegistrationReportEventProperties>(
            EventType.showPairwiseRegistrationReport,
            {
              registrationQuality: newRegistrationQuality,
            },
          );
          setIsRegistrationResultDialogOpen(true);
        },
      ),
    );

    let rawPose: IPose;
    // From jsonRevision 1 onwards, the transformation is in left-handed, y-up coordinate system
    if (registrationResult.jsonRevision >= 1) {
      // The transformation is in left-handed, y-up coordinate system, so we need to convert to LHZ
      rawPose = convertLHYtoLHZ(registrationResult.transformation);
    } else {
      // The transformation is in left-handed, z-up coordinate system
      rawPose = registrationResult.transformation;
    }

    if (!validatePose(rawPose)) {
      openToast(getRegistrationFeedbackOnError());
      return;
    }
    registrationCompleted.emit();
    updateTransform(rawPose);
  }, [
    dispatch,
    openToast,
    registrationResult,
    registrationTask,
    registrationQuality,
    setRegistrationQuality,
    updateTransform,
    registrationCompleted,
    thresholdSet,
  ]);

  return (
    <>
      <Backdrop
        sx={{
          position: "absolute",
          color: "white",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isRegistrationInProgress}
      >
        <CircularProgress
          color="inherit"
          variant={registrationTask ? "determinate" : "indeterminate"}
          value={registrationTask?.progress}
          showPercentage
          size={60}
        />
      </Backdrop>
      <FaroDialog
        open={isRegistrationResultDialogOpen}
        title="Automatic Registration Quality"
        size="l"
        onClose={() => setIsRegistrationResultDialogOpen(false)}
      >
        {regMetrics && (
          <RegistrationMetrics {...regMetrics} thresholdSet={thresholdSet} />
        )}
      </FaroDialog>
    </>
  );
}

/**
 * Transforms the pose from left-handed, y-up coordinate system to left-handed, z-up coordinate system
 *
 * @param pose The pose to be converted
 * @returns pose in left-handed, z-up coordinate system
 */
function convertLHYtoLHZ(pose: IPose): IPose {
  // creating a shallow copies, as input pose might be read-only
  const outPose = { ...pose };
  const inPos = { ...pose.pos };
  const inRot = { ...pose.rot };

  // Convert to left-handed, z-up coordinate system
  outPose.pos = {
    x: inPos.x ?? 0,
    y: inPos.z ?? 0,
    z: -(inPos.y ?? 0),
  };

  outPose.rot = {
    x: inRot.x ?? 0,
    y: inRot.z ?? 0,
    z: -(inRot.y ?? 0),
    w: inRot.w ?? 1,
  };
  return outPose;
}
