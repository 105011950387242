import {
  selectCrossSectionEnabled,
  selectSheetToCloudAlignmentSheetElevation,
} from "@/store/modes/sheet-to-cloud-alignment-mode-selectors";
import { setCrossSectionEnabled } from "@/store/modes/sheet-to-cloud-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { CrossSectionToggleButton } from "../alignment-modes-commons/cross-section-toggle-button";

/**
 * @returns sheet to cloud alignment overlay screen layout
 */
export function SheetToCloudAlignmentOverlayScreen(): JSX.Element {
  const dispatch = useAppDispatch();

  const isCrossSectionEnabled = useAppSelector(selectCrossSectionEnabled);

  const sheetElevation = useAppSelector(
    selectSheetToCloudAlignmentSheetElevation,
  );

  return (
    <CrossSectionToggleButton
      isEnabled={isCrossSectionEnabled}
      elevation={sheetElevation}
      onToggleCrossSections={() => {
        dispatch(setCrossSectionEnabled(!isCrossSectionEnabled));
      }}
    />
  );
}
