import {
  EventType,
  MoveDataSetToSheetProperties,
} from "@/analytics/analytics-events";
import { ElementIconType } from "@/components/ui/icons/element-icon-type";
import { changeMode } from "@/store/mode-slice";
import { selectAreaFor } from "@/store/selections-selectors";
import { setActiveElement } from "@/store/selections-slice";
import { selectIElement } from "@faro-lotv/app-component-toolbox";
import {
  Dropdown,
  FaroText,
  FaroVar,
  NoTranslate,
  Option,
  TruncatedFaroText,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import {
  IElementAreaSection,
  isIElementAreaSection,
} from "@faro-lotv/ielement-types";
import {
  fetchProjectIElements,
  selectAllIElementsOfType,
} from "@faro-lotv/project-source";
import { createMutationMoveDataSession } from "@faro-lotv/service-wires";
import { Box } from "@mui/material";
import { useMemo, useState } from "react";
import { ContextMenuAction, ContextMenuActionType } from "../action-types";

export const MOVE_DATA_SESSION_ACTION: ContextMenuAction = {
  type: ContextMenuActionType.moveDataSession,
  label: "Move",
  icon: ElementIconType.MoveElement,
  handler: async ({
    elementID,
    state,
    createDialog,
    setConfirmButtonDisabled,
    apiClients,
    dispatch,
    errorHandlers,
    openToast,
  }) => {
    const iElement = selectIElement(elementID)(state);
    if (!iElement) return;

    let targetArea: IElementAreaSection | undefined;

    const currentArea = selectAreaFor(iElement)(state);
    const allAreas = selectAllIElementsOfType(isIElementAreaSection)(state);

    if (!currentArea) {
      openToast({ title: "The element cannot be moved", variant: "error" });
      return;
    }

    // Create edit confirmation dialog
    await createDialog({
      title: (
        <>
          Move <FaroVar>{iElement.name}</FaroVar>
        </>
      ),
      confirmText: "Move",
      isConfirmDisabled: true,
      content: (
        <MoveDataSessionForm
          onTargetChanged={(value) => {
            targetArea = value;
            setConfirmButtonDisabled?.(!value || value.id === currentArea.id);
          }}
          currentArea={currentArea}
          allAreas={allAreas}
          hasDataSetWorldPose={!!iElement.pose?.isWorldPose}
        />
      ),
      allowToCloseWithoutCancelling: true,
      size: "s",
      showXButton: true,
      onConfirm: async () => {
        if (!targetArea) return false;

        Analytics.track<MoveDataSetToSheetProperties>(
          EventType.moveDataSetToSheet,
          {
            elementType: iElement.type,
            // Need to nullish coalesced to undefined here because null is not allowed
            elementTypeHint: iElement.typeHint ?? undefined,
          },
        );

        const targetAreaId = targetArea.id;

        try {
          await apiClients.projectApiClient.applyMutations([
            createMutationMoveDataSession(iElement.id, targetAreaId),
          ]);

          // Fetch the changed sub-tree and update the local copy of the project
          await dispatch(
            fetchProjectIElements({
              fetcher: () =>
                apiClients.projectApiClient.getAllIElements({
                  ancestorIds: [currentArea.id, targetAreaId],
                }),
            }),
          );

          // The app doesn't behave well when elements change positions.
          // Instead try to open the overview at its new location to avoid the camera being stuck in the void.
          dispatch(setActiveElement(iElement.id));
          dispatch(changeMode("overview"));

          openToast({
            title: (
              <>
                <FaroVar>{iElement.name}</FaroVar> moved to{" "}
                <FaroVar>{targetArea.name}</FaroVar>
              </>
            ),
            message: "Make sure your data set is still aligned correctly",
          });

          return true;
        } catch (error) {
          errorHandlers.handleErrorWithDialog({
            title: "Unable to move Element",
            error,
          });
          return false;
        }
      },
    });
  },
};

type MoveDataSessionFormProps = {
  /** Callback executed when the target changed */
  onTargetChanged(iElement: IElementAreaSection | undefined): void;

  /** The current area of the iElement */
  currentArea: IElementAreaSection;

  /** The list of all available areas the element can be moved into */
  allAreas: IElementAreaSection[];

  /** Whether the moved data set has a world pose (used to show an alignment hint) */
  hasDataSetWorldPose: boolean;
};

function MoveDataSessionForm({
  onTargetChanged,
  currentArea,
  allAreas,
  hasDataSetWorldPose,
}: MoveDataSessionFormProps): JSX.Element {
  const [selectedArea, setSelectedArea] = useState<
    IElementAreaSection | undefined
  >(currentArea);

  const options: Option[] = useMemo(
    () =>
      allAreas.map((area) => ({
        label: <NoTranslate>{area.name}</NoTranslate>,
        value: area.id,
        key: area.id,
      })),
    [allAreas],
  );

  // The alignment will only be preserved by the mutation, if the data is referenced to the world in both locations
  const willLoseAlignment =
    selectedArea !== currentArea &&
    (!selectedArea?.pose?.isWorldPose || !currentArea.pose?.isWorldPose) &&
    !hasDataSetWorldPose;

  return (
    <>
      <Box component="div" sx={{ mb: 3 }}>
        <TruncatedFaroText variant="bodyM">
          Current sheet:{" "}
          <FaroVar>
            <b>{currentArea.name}</b>
          </FaroVar>
        </TruncatedFaroText>
        {willLoseAlignment && (
          <FaroText variant="bodyM">
            By moving the data you will lose its alignment.
          </FaroText>
        )}
      </Box>

      <Dropdown
        label="Move to sheet"
        autoFocus
        fullWidth
        value={selectedArea?.id}
        options={options}
        onChange={(ev) => {
          const area = allAreas.find((area) => area.id === ev.target.value);
          setSelectedArea(area);
          onTargetChanged(area);
        }}
      />
    </>
  );
}
