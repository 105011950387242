import { runtimeConfig } from "@/runtime-config";
import { HelpCenterMenu } from "@faro-lotv/flat-ui";

/**
 * @returns the Faro HelpCenterMenu configured for the Sphere Viewer app
 */
export function ViewerHelpCenterMenu(): JSX.Element {
  return (
    <HelpCenterMenu
      sendFeedback={
        runtimeConfig.externalLinks.feedbackForm
          ? () => {
              window.open(runtimeConfig.externalLinks.feedbackForm, "blank");
            }
          : undefined
      }
    />
  );
}
