import { planesToArray } from "@/modes/clipping-box-mode/planes-to-array";
import { IQuat, IVec3 } from "@faro-lotv/ielement-types";
import { memberWithPrivateData, SupportedCamera } from "@faro-lotv/lotv";
import {
  CachedWorldTransform,
  convertThreeToIElementTransform,
} from "@faro-lotv/project-source";
import {
  Euler,
  Matrix4,
  OrthographicCamera,
  Plane,
  Quaternion,
  Vector3,
} from "three";

/** Extra padding(m) added to the camera framing */
const CAMERA_MARGIN = 0.1;

export type VolumeInfo = {
  /** Position of the volume */
  position: IVec3 | null;

  /** Rotation of the volume */
  rotation: IQuat | null;

  /** Sizes of the volume box */
  size: IVec3 | null;
};

/**
 * @param clippingPlanes the local clipping planes
 * @param transform the transform of the object
 * @returns the clipping planes in world space
 */
export const createClippingPlanes = memberWithPrivateData(() => {
  const mat = new Matrix4();
  const planes = [
    new Plane(),
    new Plane(),
    new Plane(),
    new Plane(),
    new Plane(),
    new Plane(),
  ];

  return (
    clippingPlanes: Plane[],
    transform: CachedWorldTransform,
  ): Plane[] => {
    mat.fromArray(transform.worldMatrix);

    planes.forEach((plane, i) => {
      plane.copy(clippingPlanes[i]);
      plane.applyMatrix4(mat);
    });

    return [...planes];
  };
});

/**
 * @param clippingPlanes the clipping planes in world space
 * @returns the matrix representing the clipping planes box
 */
export const createClippingPlanesMatrix = memberWithPrivateData(() => {
  const mat = new Matrix4();

  return (clippingPlanes: Plane[]): Matrix4 => {
    // The generated matrix from the function is row-major because it is used by the backend,
    // three.js uses column-major matrices, so the matrix is transposed
    return mat.fromArray(planesToArray(clippingPlanes)).transpose();
  };
});

type BoxAndVerticalRotation = {
  /** The center of the box */
  center: Vector3;
  /** The size of the box */
  size: Vector3;
  /** The rotation around the Y axis */
  rotationAroundY: number;
};

/**
 * @param matrix the matrix to extract the box from
 * @returns the center of the box, the size of the box and the rotation around the Y axis
 */
export const matrixToBoxAroundY = memberWithPrivateData(() => {
  const xAxis = new Vector3();
  const yAxis = new Vector3();
  const zAxis = new Vector3();
  const center = new Vector3();
  const size = new Vector3();
  const euler = new Euler();

  return (matrix: Matrix4): BoxAndVerticalRotation => {
    // First three columns of the matrix are the rotation with the scale
    // But they also are the three directions of the sides of the box
    matrix.extractBasis(xAxis, yAxis, zAxis);

    const xSize = xAxis.length();
    const ySize = yAxis.length();
    const zSize = zAxis.length();

    size.set(xSize, ySize, zSize);

    // The matrix's position is a bottom corner of the box
    // By adding to it half of each side, we get the center of the box
    center
      .setFromMatrixPosition(matrix)
      .add(xAxis.multiplyScalar(0.5))
      .add(yAxis.multiplyScalar(0.5))
      .add(zAxis.multiplyScalar(0.5));

    euler.setFromRotationMatrix(matrix, "YZX");

    return {
      center,
      size,
      rotationAroundY: euler.y,
    };
  };
});

/**
 * Frame the camera on the box
 *
 * @param camera the camera to frame
 * @param boxInfo the information about the box to frame the camera on
 * @param size the size of the viewport
 * @param size.width the width of the viewport
 * @param size.height the height of the viewport
 */
export const centerCameraOnBoxAroundY = memberWithPrivateData(() => {
  const offset = new Vector3();
  const mat1 = new Matrix4();
  const mat2 = new Matrix4();
  const mat3 = new Matrix4();

  return (
    camera: SupportedCamera,
    boxInfo: BoxAndVerticalRotation,
    size: { width: number; height: number },
  ): void => {
    const { center, size: boxSize, rotationAroundY } = boxInfo;

    // Place the camera above the center of the box
    camera.position
      .copy(center)
      .add(offset.set(0, boxSize.y / 2 + CAMERA_MARGIN, 0));
    // Make the camera look down at the center of the box
    camera.lookAt(center);

    const pos = camera.position;

    mat1.makeTranslation(pos.x, pos.y, pos.z);
    // Now the camera is rotated to provide a top-down view.
    // We add an extra rotation to align it to the bounding box orientation.
    mat2.makeRotationY(rotationAroundY);
    mat3.makeTranslation(-pos.x, -pos.y, -pos.z);
    const mat4 = mat1.multiply(mat2).multiply(mat3);

    camera.applyMatrix4(mat4);

    // The camera is centered so that the entire clipping box is always visible
    if (camera instanceof OrthographicCamera) {
      const aspectRatio = size.width / size.height;

      // Frame the camera on the longest side of the box
      const cameraTop =
        Math.max(boxSize.x / aspectRatio, boxSize.z) / 2 + CAMERA_MARGIN;
      camera.top = cameraTop;
      camera.bottom = -cameraTop;
      camera.right = cameraTop * aspectRatio;
      camera.left = -camera.right;
    }

    camera.near = CAMERA_MARGIN;
    camera.far = boxSize.y + 2 * CAMERA_MARGIN;

    camera.updateProjectionMatrix();
  };
});

/**
 * @param clippingPlanes clipping planes in local space
 * @param matrix the transform of the object
 * @returns the position, rotation and size of the volume
 */
export const volumeFromPlanes = memberWithPrivateData(() => {
  const position = new Vector3();
  const quaternion = new Quaternion();
  const scale = new Vector3();

  return (
    clippingPlanes?: Plane[],
    matrix?: CachedWorldTransform,
  ): VolumeInfo | undefined => {
    if (!clippingPlanes || !matrix) return;

    const worldClippingPlanes = createClippingPlanes(clippingPlanes, matrix);
    const worldClippingPlanesMatrix =
      createClippingPlanesMatrix(worldClippingPlanes);
    worldClippingPlanesMatrix.decompose(position, quaternion, scale);

    const iElementTransform = convertThreeToIElementTransform({
      position,
      quaternion,
      scale,
    });

    // Convert from right-handed to left-handed
    return {
      // The volume position is the min point of the box
      position: iElementTransform.pos
        ? {
            x: iElementTransform.pos.x,
            y: iElementTransform.pos.y,
            z: iElementTransform.pos.z * -1,
          }
        : null,
      rotation: iElementTransform.rot
        ? {
            x: iElementTransform.rot.x,
            y: iElementTransform.rot.y,
            z: iElementTransform.rot.z * -1,
            w: iElementTransform.rot.w * -1,
          }
        : null,
      size: iElementTransform.scale
        ? {
            x: iElementTransform.scale.x,
            y: iElementTransform.scale.y,
            z: iElementTransform.scale.z,
          }
        : null,
    };
  };
});
