import { FaroButton } from "@faro-lotv/flat-ui";

// TODO: Implement annotation sorting (https://faro01.atlassian.net/browse/SWEB-5425)
/** @returns the button and menu to select the annotation list sorting */
export function SortMenuButton(): JSX.Element {
  return (
    <FaroButton sx={{ color: "black" }} variant="ghost">
      Sort By
    </FaroButton>
  );
}
