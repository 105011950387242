import { IDENTITY } from "@/alignment-tool/store/alignment-slice";
import {
  alignmentTransformToMatrix4,
  matrix4ToAlignmentTransform,
} from "@/alignment-tool/utils/alignment-transform";
import { useCurrentProjectApiClient } from "@/components/common/project-provider/project-loading-context";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { selectActiveCadModel } from "@/store/cad/cad-slice";
import { changeMode } from "@/store/mode-slice";
import {
  selectCloudForCadAlignment,
  selectCloudToCadAlignmentCloudElevation,
  selectCloudToCadAlignmentLayout,
  selectCloudToCadAlignmentModelElevation,
  selectCloudToCadAlignmentStep,
  selectIncrementalCloudTransform,
} from "@/store/modes/cloud-to-cad-alignment-mode-selectors";
import {
  CloudToCadAlignmentStep,
  resetCloudToCadAlignment,
  setCloudToCadAlignmentLayout,
} from "@/store/modes/cloud-to-cad-alignment-mode-slice";
import { AlignmentViewLayout } from "@/store/modes/sheet-to-cad-alignment-mode-slice";
import { store } from "@/store/store";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { setShowSpinner } from "@/store/ui/ui-slice";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import { useToast } from "@faro-lotv/flat-ui";
import { assert } from "@faro-lotv/foundation";
import { isIElementGenericPointCloudStream } from "@faro-lotv/ielement-types";
import {
  fetchProjectIElements,
  selectIElement,
  selectIElementProjectApiLocalPose,
} from "@faro-lotv/project-source";
import { useCallback } from "react";
import { AlignmentViewLayoutToggle } from "../alignment-modes-commons/alignment-view-layout-toggle";
import { CloudToCadAlignmentProgressBar } from "./cloud-to-cad-alignment-progress-bar";
import { CloudToCadAlignmentSplitScreen } from "./cloud-to-cad-alignment-split-screen";

/** @returns The overlay for the cloud to CAD alignment mode */
export function CloudToCadAlignmentModeOverlay(): JSX.Element {
  const { openToast } = useToast();
  const dispatch = useAppDispatch();
  const client = useCurrentProjectApiClient();
  const { handleErrorWithToast } = useErrorHandlers();

  const activeCloudID = useAppSelector(selectCloudForCadAlignment);
  assert(activeCloudID, "point cloud for alignment not defined");

  const activeCloud = useAppSelector(selectIElement(activeCloudID));

  const activeCad = useAppSelector(selectActiveCadModel);
  assert(
    activeCad && activeCloud && isIElementGenericPointCloudStream(activeCloud),
    "CAD Alignment Mode requires a CAD model and cloud",
  );

  const applyCloudMutation = useCallback(async () => {
    dispatch(setShowSpinner(true));

    const initialCloudWorldMatrix = selectIElementWorldMatrix4(activeCloud.id)(
      store.getState(),
    );

    const initialCloudWorldTransform = matrix4ToAlignmentTransform(
      initialCloudWorldMatrix,
    );

    const incrementalTransform = selectIncrementalCloudTransform(
      store.getState(),
    );

    const cloudElevation = selectCloudToCadAlignmentCloudElevation(
      store.getState(),
    );
    const modelElevation = selectCloudToCadAlignmentModelElevation(
      store.getState(),
    );

    const resultElevation =
      initialCloudWorldTransform.position[1] + modelElevation - cloudElevation;

    const transformToApply = incrementalTransform ?? IDENTITY;

    const cloudToCadMatrix = alignmentTransformToMatrix4(
      transformToApply,
    ).multiply(initialCloudWorldMatrix);

    const cloudWorldTransform = selectIElementProjectApiLocalPose(
      activeCloud,
      cloudToCadMatrix,
    )(store.getState());
    cloudWorldTransform.pos.y = resultElevation;

    try {
      const response = await client.applyCloudToBimAlignment({
        pointCloudId: activeCloud.id,
        bimModelId: activeCad.id,
        pointCloudPose: {
          isWorldPose: true,
          pos: cloudWorldTransform.pos,
          rot: cloudWorldTransform.rot,
          scale: cloudWorldTransform.scale,
        },
        cloudToBimElevation: modelElevation,
      });

      const modifiedElementIds = response.modifiedIElements.map(
        (element) => element.id,
      );

      // Fetch the changed section area sub-tree and update the local copy of the project
      // that new alignment will be used without reloading whole project
      dispatch(
        fetchProjectIElements({
          fetcher: async () => {
            // Refresh the area node to get new transform
            return await client.getAllIElements({
              ancestorIds: modifiedElementIds,
            });
          },
        }),
      );

      // at the end of alignment cycle reset temporary data to prevent reusing it in the next session of alignment
      dispatch(resetCloudToCadAlignment());

      dispatch(setShowSpinner(false));
      openToast({
        title: "Alignment Completed",
        variant: "success",
      });
    } catch (error) {
      handleErrorWithToast({
        title: "Failed to save new alignment",
        error,
      });

      dispatch(setShowSpinner(false));

      return;
    }

    // after alignment force switch to overview mode as most convenient to validate alignment result in main 3D view
    dispatch(changeMode("overview"));
  }, [
    dispatch,
    activeCloud,
    activeCad,
    client,
    openToast,
    handleErrorWithToast,
  ]);

  const step = useAppSelector(selectCloudToCadAlignmentStep);
  const alignmentLayout = useAppSelector(selectCloudToCadAlignmentLayout);

  const changeAlignmentScreenLayout = useCallback(
    (value: AlignmentViewLayout) =>
      dispatch(setCloudToCadAlignmentLayout(value)),
    [dispatch],
  );

  return (
    <>
      <CloudToCadAlignmentProgressBar apply={applyCloudMutation} />
      {(step === CloudToCadAlignmentStep.setElevations ||
        alignmentLayout === AlignmentViewLayout.splitScreen) && (
        <CloudToCadAlignmentSplitScreen />
      )}
      {step === CloudToCadAlignmentStep.alignIn2d && (
        <AlignmentViewLayoutToggle
          alignmentLayout={alignmentLayout}
          changeAlignmentScreenLayout={changeAlignmentScreenLayout}
        />
      )}
    </>
  );
}
