import { HorizontalResizeContainer } from "@/components/ui/resize-container";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { MultiCloudRegistrationReportView } from "@/registration-tools/common/registration-report/multicloud-registration-report";
import { useAppSelector } from "@/store/store-hooks";
import { redirectToViewer } from "@/utils/redirects";
import {
  Banner,
  CloseIcon,
  FaroButton,
  FaroIconButton,
  FaroText,
  neutral,
  useToast,
} from "@faro-lotv/flat-ui";
import { GUID } from "@faro-lotv/foundation";
import { IElementGenericPointCloudStream } from "@faro-lotv/ielement-types";
import {
  getProjectRawDataPage,
  selectDashboardUrl,
} from "@faro-lotv/project-source";
import {
  RegistrationRevision,
  RegistrationState,
  RevisionScanEntity,
  isMergeConflictError,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { Box, Stack } from "@mui/material";
import { useCallback, useState } from "react";
import { useLoadRegistrationReport } from "../loading/use-load-registration-report";
import { RevisionScansScene } from "../rendering/revision-scans-scene";
import {
  selectPointCloudStreamForHoveredEntity,
  selectPointCloudStreamForSelectedEntity,
} from "../store/data-preparation-ui/data-preparation-ui-selectors";
import { DataPreparationSidebar } from "../ui/data-preparation-sidebar";
import { PointCloudsProcessingBanner } from "../ui/point-clouds-processing-banner";

type InspectAndPublishViewProps = {
  /** The revision to show the inspect & publish view for. */
  revision: RegistrationRevision;

  /** The scans that are part of the revision. */
  scanEntities: RevisionScanEntity[];

  /** The point cloud streams for the scan entities. undefined if they are still loading. */
  pointCloudStreams?: IElementGenericPointCloudStream[];
};

/**
 * @returns The inspect & publish step of the data preparation workflow.
 */
export function InspectAndPublishView({
  revision,
  scanEntities,
  pointCloudStreams,
}: InspectAndPublishViewProps): JSX.Element {
  const registrationReport = useLoadRegistrationReport(revision.reportUri);
  const [isReportOpen, setIsReportOpen] = useState(false);

  const hoveredPointCloudId = useAppSelector(
    selectPointCloudStreamForHoveredEntity,
  );
  const selectedPointCloud = useAppSelector(
    selectPointCloudStreamForSelectedEntity,
  );

  return (
    <Stack sx={{ height: "100%", width: "100%" }}>
      <InspectAndPublishStatusBanner revisionState={revision.state} />
      <PointCloudsProcessingBanner
        scanEntities={scanEntities}
        pointCloudStreams={pointCloudStreams}
      />

      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <DataPreparationSidebar
          title="Registered scans"
          description="Publish the registration result as a merged project point to
              your SphereXG project."
          buttons={
            <>
              <FaroButton
                variant="secondary"
                isLoading={!registrationReport}
                onClick={() => setIsReportOpen(!isReportOpen)}
              >
                {registrationReport && isReportOpen ? "Hide" : "Show"} quality
                report
              </FaroButton>
              <PublishButton revision={revision} />
            </>
          }
        />

        {/* 3D scene */}
        <RevisionScansScene
          scanEntities={scanEntities}
          pointCloudStreams={pointCloudStreams}
          registrationReport={registrationReport}
          hoveredPointCloudId={hoveredPointCloudId?.id}
          selectedPointCloudId={selectedPointCloud?.id}
        />

        {/* Quality report */}
        {isReportOpen && !!registrationReport && (
          <Box
            component="div"
            sx={{
              width: 0,
              height: "100%",
              position: "relative",
            }}
          >
            <HorizontalResizeContainer
              initialWidth={600}
              minWidth={350}
              maxWidth={600}
              handleSide="left"
              sx={{
                position: "absolute",
                right: 0,
                height: "100%",
              }}
            >
              <Stack
                sx={{
                  p: 1,
                  overflowX: "auto",
                  width: "100%",
                  height: "100%",
                  backgroundColor: neutral[0],
                }}
                gap={1}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <FaroText variant="heading16">Quality Report</FaroText>
                  <FaroIconButton onClick={() => setIsReportOpen(false)}>
                    <CloseIcon />
                  </FaroIconButton>
                </Stack>
                <MultiCloudRegistrationReportView {...registrationReport} />
              </Stack>
            </HorizontalResizeContainer>
          </Box>
        )}
      </Stack>
    </Stack>
  );
}

type PublishButtonProps = {
  /** The revision to publish. */
  revision: RegistrationRevision;
};

/** @returns A button to publish a revision, i.e. merge it into the main revision. */
function PublishButton({ revision }: PublishButtonProps): JSX.Element {
  const [isPublishLoading, setIsPublishLoading] = useState(false);

  const { handleErrorWithToast } = useErrorHandlers();
  const { projectApiClient } = useApiClientContext();

  const openMergeConflictToast = useOpenMergeConflictToast(
    projectApiClient.projectId,
  );

  // Only registered revisions can be published
  const isDisabled = revision.state !== RegistrationState.registered;

  const publishRevision = useCallback(async () => {
    setIsPublishLoading(true);

    try {
      await projectApiClient.applyRegistrationRevisionToMain(revision.id);
      redirectToViewer(projectApiClient.projectId);
    } catch (error) {
      if (isMergeConflictError(error)) {
        openMergeConflictToast();
        return;
      }

      handleErrorWithToast({
        error,
        title: "Failed to publish data",
      });
    }

    setIsPublishLoading(false);
  }, [
    projectApiClient,
    revision.id,
    handleErrorWithToast,
    openMergeConflictToast,
  ]);

  return (
    <FaroButton
      variant="primary"
      disabled={isDisabled}
      isLoading={isPublishLoading}
      onClick={publishRevision}
    >
      Publish
    </FaroButton>
  );
}

type InspectAndPublishStatusProps = {
  /** The current state of the registration. */
  revisionState: RegistrationState;
};

/** @returns A banner showing the current state of the workflow to the user. */
function InspectAndPublishStatusBanner({
  revisionState,
}: InspectAndPublishStatusProps): JSX.Element | null {
  switch (revisionState) {
    case RegistrationState.merged:
      return (
        <Banner variant="success" title="Dataset published">
          Your data set has been published, you can now view it in the Sphere
          Viewer.
        </Banner>
      );
    case RegistrationState.canceled:
      return (
        <Banner variant="error" title="Registration canceled">
          The registration has been canceled. Please restart the workflow.
        </Banner>
      );
    default:
      // Nothing special, just show the normal view
      return null;
  }
}

/**
 * @returns a method to open a toast informing the user about how to resolve a merge conflict for the data-preparation workflow
 * @param projectId the current project id
 */
function useOpenMergeConflictToast(projectId: GUID): () => void {
  const { openToast } = useToast();
  const dashboardUrl = useAppSelector(selectDashboardUrl);

  return useCallback(() => {
    const action = dashboardUrl
      ? {
          label: "Restart",
          onClicked: () => {
            window.location.assign(
              getProjectRawDataPage(dashboardUrl, projectId),
            );
          },
        }
      : undefined;

    openToast({
      variant: "error",
      title: "Conflicting changes",
      message:
        "Your project has been changed by another user. Restart the registration workflow to incorporate the latest changes.",
      action,
    });
  }, [dashboardUrl, openToast, projectId]);
}
