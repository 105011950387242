import {
  AlignmentTransformChangedProperties,
  EventType,
  OpenQuickHelpEventProperties,
} from "@/alignment-tool/analytics/analytics-events";
import {
  computeCloudCenteringTransformation,
  useCloudToAlignInitialPosition,
} from "@/alignment-tool/hooks/use-element-initial-position";
import { useIsScaleEnabled } from "@/alignment-tool/hooks/use-is-scale-enabled";
import {
  selectCloudToAlign,
  selectElementToAlignTransform,
  selectIsScaling,
} from "@/alignment-tool/store/alignment-selectors";
import {
  completeActiveStep,
  setElementToAlignTransform,
  setIsScaleEnabled,
  setIsScaling,
} from "@/alignment-tool/store/alignment-slice";
import { setIsQuickHelpOpen } from "@/alignment-tool/store/alignment-ui/alignment-ui-slice";
import {
  alignmentTransformToMatrix4,
  matrix4ToAlignmentTransform,
} from "@/alignment-tool/utils/alignment-transform";
import { useProjectSubTree } from "@/components/common/project-provider/project-loading-context";
import { SheetModeControls } from "@/components/r3f/controls/sheet-mode-controls";
import { TomographicOverlayViewPipeline } from "@/components/r3f/effects/tomographic-view-pipeline";
import {
  PointCloudPreview,
  PointCloudPreviewActions,
} from "@/components/r3f/renderers/point-cloud-preview";
import { SheetRenderer } from "@/components/r3f/renderers/sheet-renderer";
import {
  centerOrthoCamera,
  useCenterCameraOnPlaceholders,
} from "@/hooks/use-center-camera-on-placeholders";
import { useObjectBoundingBox } from "@/hooks/use-object-bounding-box";
import {
  PointCloudObject,
  getWeakRefToCachedObject,
  useCached3DObject,
} from "@/object-cache";
import { selectActiveAreaOrThrow } from "@/store/selections-selectors";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import {
  LodPointCloudRendererBase,
  PointCloudZAxisIcon,
  RecenterViewIcon,
  ResetScaleIcon,
  TwoPointAlignment,
  parseVector3,
  selectChildDepthFirst,
  selectIElementWorldPosition,
  useDialog,
  useToast,
} from "@faro-lotv/app-component-toolbox";
import { ToolButton, Toolbar } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { assert } from "@faro-lotv/foundation";
import {
  IElementGenericImgSheet,
  IElementImg360,
  IElementSection,
  isIElementGenericImgSheet,
} from "@faro-lotv/ielement-types";
import {
  TomographicModelPass as LotvTomographicModel,
  TOMOGRAPHIC_POINT_COUNT_BIAS,
} from "@faro-lotv/lotv";
import { Box, Snackbar, Stack, Tooltip, Typography } from "@mui/material";
import { useThree } from "@react-three/fiber";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Matrix4, OrthographicCamera, Quaternion, Vector3 } from "three";
import { CloudToSheetAlignmentStepNames, Step } from "./steps";

const MAX_POINTS_FOR_TOMOGRAPHIC_VIEW = 3000000;

const KEY_SCALE_OVERRIDE_TOAST = "key_override_scale_toast";

/** The near plane distance from the camera, in meters */
export const NEAR_PLANE_DISTANCE = 0.1;

type AlignmentSceneProps = {
  /** The active floor plan to align to */
  activeSheet: IElementGenericImgSheet;

  /** The active area align to */
  activeArea: IElementSection;
};

/**
 * @returns the cached cloud object
 */
export function useCloudToAlign(): PointCloudObject {
  const cloudToAlign = useAppSelector(selectCloudToAlign);
  assert(cloudToAlign, "Expected cloud to align in the project");
  return useCached3DObject(cloudToAlign);
}

/**
 * @returns the main scene to do the PointCloud to FloorPlan alignment
 */
function AlignmentSceneBase({
  activeSheet,
  activeArea,
}: AlignmentSceneProps): JSX.Element | null {
  const [controlsEnabled, setControlsEnabled] = useState(true);
  const isScaleEnabled = useIsScaleEnabled(activeArea);

  const camera = useThree((s) => s.camera);
  const dispatch = useAppDispatch();

  const { openToast, closeToast } = useToast();

  useEffect(() => {
    if (isScaleEnabled) {
      // Close the toast as user has decided to override the scale
      closeToast(KEY_SCALE_OVERRIDE_TOAST);
    }

    return () => {
      closeToast(KEY_SCALE_OVERRIDE_TOAST);
    };
  }, [closeToast, isScaleEnabled]);

  // Get the actual sheet and point cloud objects
  const sheet = useCached3DObject(activeSheet);
  const cloudToAlign = useCloudToAlign();

  useEffect(() => {
    if (activeArea.pose?.scale !== null && !isScaleEnabled) {
      // The area has a scale specified, hence notify the user of possible overriding of scale.
      openToast({
        key: KEY_SCALE_OVERRIDE_TOAST,
        title: "Previous Scale Applied",
        message:
          'A reference scale has already been defined for this sheet. To change it, click the "Enable Scale" button.',
        variant: "info",
        persist: true,
        action: {
          label: "Learn more",
          onClicked: () => {
            Analytics.track<OpenQuickHelpEventProperties>(
              EventType.openQuickHelp,
              {
                via: "header bar",
              },
            );

            dispatch(setIsQuickHelpOpen(true));
          },
        },
      });
    }
  }, [dispatch, activeArea, isScaleEnabled, openToast]);

  useEffect(() => {
    dispatch(completeActiveStep());
    return () => {
      dispatch(setIsScaling(false));
    };
  }, [dispatch]);

  useCloudToAlignInitialPosition(sheet, cloudToAlign);

  const currentTransform = useAppSelector(selectElementToAlignTransform);
  const sheetPosition = useAppSelector(
    selectIElementWorldPosition(activeSheet.id),
  );

  // Center the camera on mode start
  const centeringData = useCenterCameraOnPlaceholders({
    sheetElement: activeSheet,
    placeholders: new Array<IElementImg360>(),
  });
  const [initialCenteringData] = useState(() => centeringData);
  useEffect(() => {
    if (camera instanceof OrthographicCamera) {
      centerOrthoCamera(camera, initialCenteringData);
    }
  }, [camera, initialCenteringData]);

  const dpr = useThree((s) => s.gl.getPixelRatio());

  // Set the point cloud material to a something valid for tomographic rendering
  // Then set it back to what it was when we leave the mode.
  useEffect(() => {
    const oldMat = cloudToAlign.material;
    const maxPointsInGPU = cloudToAlign.visibleNodesStrategy.maxPointsInGpu;

    cloudToAlign.material =
      LotvTomographicModel.createTomographicMaterialPoints(dpr);
    cloudToAlign.visibleNodesStrategy.maxPointsInGpu =
      MAX_POINTS_FOR_TOMOGRAPHIC_VIEW;

    return () => {
      cloudToAlign.material.dispose();
      cloudToAlign.material = oldMat;
      cloudToAlign.visibleNodesStrategy.maxPointsInGpu = maxPointsInGPU;
    };
  }, [cloudToAlign, dpr]);

  const onTransformChanged = useCallback(
    (position: Vector3, quaternion: Quaternion, scale: Vector3) => {
      Analytics.track(EventType.alignmentTransformChanged, {
        change: AlignmentTransformChangedProperties.pose2Dchanged,
      });

      dispatch(
        setElementToAlignTransform({
          position: position.toArray(),
          quaternion: [quaternion.x, quaternion.y, quaternion.z, quaternion.w],
          scale: scale.toArray(),
        }),
      );
    },
    [dispatch],
  );
  const onPinsCountChanged = useCallback(
    (pinsCount: number) => {
      if (pinsCount === 2) {
        dispatch(setIsScaling(true));
      } else {
        dispatch(setIsScaling(false));
      }
      Analytics.track(EventType.alignmentPinsCountChanged, {
        newCount: pinsCount,
      });
    },
    [dispatch],
  );

  const box = useMemo(
    () => cloudToAlign.tree.boundingBox.clone(),
    [cloudToAlign],
  );
  const worldTransform = useAppSelector(
    selectIElementWorldMatrix4(cloudToAlign.iElement.id),
  );
  // Based on the bounding box containing both the data to align and the sheet,
  // this hook computes the camera frustum and position that allow to frame everything
  // without frustum culling. Since the two points alignment does not move the data in the Y
  // direction and we are using an orthographic camera, this is only needed once.
  useEffect(() => {
    const sceneBox = box.clone().applyMatrix4(worldTransform);
    sceneBox.expandByPoint(parseVector3(sheetPosition));

    camera.position.y = sceneBox.max.y + NEAR_PLANE_DISTANCE;
    camera.near = NEAR_PLANE_DISTANCE;
    camera.far = camera.position.y - sceneBox.min.y + NEAR_PLANE_DISTANCE;
    camera.updateMatrixWorld(true);
    camera.updateProjectionMatrix();
  }, [box, camera, sheetPosition, worldTransform]);

  return (
    <>
      {/** The ambient light is needed for the CAD in tomographic mode */}
      <ambientLight intensity={1.0} />
      <SheetRenderer sheet={sheet} />
      {currentTransform && (
        <TwoPointAlignment
          onPointerDown={() => setControlsEnabled(false)}
          onPointerUp={() => setControlsEnabled(true)}
          onPinsCountChanged={onPinsCountChanged}
          onTransformChanged={onTransformChanged}
          isScaleEnabled={isScaleEnabled}
          {...currentTransform}
        >
          <LodPointCloudRendererBase pointCloud={cloudToAlign} />
        </TwoPointAlignment>
      )}

      <TomographicOverlayViewPipeline
        sheet={activeSheet}
        camera={camera}
        bias={TOMOGRAPHIC_POINT_COUNT_BIAS}
      />

      <SheetModeControls
        camera={camera}
        referencePlaneHeight={sheetPosition[1]}
        enabled={controlsEnabled}
      />
    </>
  );
}

export const alignmentStep: Step = {
  name: CloudToSheetAlignmentStepNames.alignIn2d,

  /**
   * @returns The top down alignment scene for doing two point alignment.
   *
   * Upon completion the final transform of the point cloud can be obtained from
   * pointCloud.getWorldPosition, pointCloud.getWorldQuaternion, pointCloud.getWorldScale
   */
  Scene() {
    const area = useAppSelector(selectActiveAreaOrThrow());
    const isLoading = useProjectSubTree(area.id);

    // Get active sheet and cloud elements from app store
    const activeSheet = useAppSelector(
      selectChildDepthFirst(area, isIElementGenericImgSheet),
    );
    if (!activeSheet) {
      if (!isLoading) {
        throw Error("Cannot retrieve the active sheet to do sheet-alignment");
      }
      return null;
    }
    return <AlignmentSceneBase activeSheet={activeSheet} activeArea={area} />;
  },

  Overlay() {
    const store = useAppStore();
    const dispatch = useAppDispatch();

    const area = useAppSelector(selectActiveAreaOrThrow());

    // Track if we have to show the snackbar informing the user that they can scale
    const [showScalingEnabledSnackbar, setShowScalingEnabledSnackbar] =
      useState(false);

    const isScaling = useAppSelector(selectIsScaling);

    // User has used the scaling tool, we don't need to inform them anymore
    useEffect(() => {
      if (isScaling) setShowScalingEnabledSnackbar(false);
    }, [isScaling]);

    const recenterCallback = useCallback(() => {
      Analytics.track(EventType.recenterPointCloudOnSheet);

      const state = store.getState();

      const activeSheet = selectChildDepthFirst(
        area,
        isIElementGenericImgSheet,
      )(state);

      const sheet = getWeakRefToCachedObject(activeSheet);
      assert(sheet, "A loaded sheet is required to re-align a point cloud");

      const worldMatrix = selectElementToAlignTransform(state);

      const element = getWeakRefToCachedObject(selectCloudToAlign(state));
      assert(element, "The element to align needs to be loaded to recenter it");
      dispatch(
        setElementToAlignTransform(
          computeCloudCenteringTransformation(worldMatrix, element, sheet),
        ),
      );
    }, [store, area, dispatch]);

    const isScaleEnabled = useIsScaleEnabled(area);
    const { createDialog } = useDialog();
    const resetScaleCallback = useCallback(async () => {
      Analytics.track(EventType.enablePointCloudScale);

      const confirmed = await createDialog({
        title: "Enable Scale?",
        confirmText: "Enable",
        content: (
          <Typography>
            Enabling the scale will also <b>affect previous alignments</b>. The
            action cannot be undone.
            <br />
            <br />
            The point cloud coordinate system and its geolocation will remain
            unchanged.
          </Typography>
        ),
        showCancelButton: true,
      });
      if (confirmed) {
        Analytics.track(EventType.confirmEnableScale);

        // Scaling wasn't enabled before, but it is now; inform the user
        setShowScalingEnabledSnackbar(true);

        dispatch(setIsScaleEnabled(true));
      } else {
        Analytics.track(EventType.cancelEnableScale);
      }
    }, [createDialog, dispatch]);

    const cloudToAlign = useCloudToAlign();

    const currentTransform = useAppSelector(selectElementToAlignTransform);
    const previewActions = useRef<PointCloudPreviewActions>(null);
    const box = useObjectBoundingBox(cloudToAlign);

    const rotate90degrees = useCallback(() => {
      const translation = box?.getCenter(new Vector3()) ?? new Vector3();
      const transform = currentTransform
        ? alignmentTransformToMatrix4(currentTransform)
        : new Matrix4();
      transform.multiply(
        new Matrix4().makeTranslation(
          translation.x,
          translation.y,
          translation.z,
        ),
      );
      transform.multiply(new Matrix4().makeRotationX(Math.PI * 0.5));
      transform.multiply(
        new Matrix4().makeTranslation(
          -translation.x,
          -translation.y,
          -translation.z,
        ),
      );

      previewActions.current?.updatePreview(transform);

      dispatch(
        setElementToAlignTransform(matrix4ToAlignmentTransform(transform)),
      );
    }, [box, currentTransform, dispatch]);

    return (
      <Stack
        sx={{
          width: "100%",
          height: "100%",
          alignItems: "flex-end",
          justifyContent: "space-between",
        }}
      >
        <Snackbar
          open={showScalingEnabledSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          message="You can now use two anchor points and scale your point cloud"
          sx={{ position: "absolute" }}
          onClick={(e) => e.nativeEvent.stopImmediatePropagation()}
        />
        <Box component="div" />
        <Toolbar sx={{ mr: 3 }}>
          <>
            <Tooltip title="Enable Scale" placement="left">
              <ToolButton
                aria-label="enable scale"
                selected={isScaleEnabled}
                onClick={(e) => {
                  e.nativeEvent.stopImmediatePropagation();
                  if (!isScaleEnabled) resetScaleCallback();
                }}
              >
                <ResetScaleIcon />
              </ToolButton>
            </Tooltip>
            <Tooltip title="Re-center point cloud on sheet" placement="left">
              <ToolButton
                aria-label="re-center point cloud"
                onClick={(e) => {
                  e.nativeEvent.stopImmediatePropagation();
                  recenterCallback();
                }}
              >
                <RecenterViewIcon />
              </ToolButton>
            </Tooltip>
            <Tooltip title="Rotate point cloud by 90°" placement="left">
              <ToolButton
                aria-label="rotate point cloud"
                onClick={(e) => {
                  e.nativeEvent.stopImmediatePropagation();
                  rotate90degrees();
                }}
              >
                <PointCloudZAxisIcon />
              </ToolButton>
            </Tooltip>
          </>
        </Toolbar>
        <Box
          component="div"
          sx={{
            width: "18.75rem",
            height: "11rem",
            zIndex: 1,
            border: ({ palette }) => `1px solid ${palette.gray200}`,
            margin: 3,
            borderRadius: "6px",
          }}
        >
          <PointCloudPreview
            pointCloud={cloudToAlign}
            initialPose={currentTransform}
            actions={previewActions}
          />
        </Box>
      </Stack>
    );
  },
};
