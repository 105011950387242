import { useClipboxEvents } from "@/components/common/clipbox-events-context";
import { useTransparencySettingsContext } from "@/components/common/transparency-sliders/transparency-settings-context";
import { PointCloudSubscene } from "@/components/r3f/effects/point-cloud-subscene";
import { CadModelObject, PointCloudObject, SheetObject } from "@/object-cache";
import { useAutoClipBox } from "@/tools/use-auto-clip-box";
import {
  ComposeFramebuffersPass,
  EffectPipeline,
  FilteredRenderPass,
  FxaaPass,
  StoreFboPass,
  StoreFboPassObj,
  SubScene,
  useTypedEvent,
} from "@faro-lotv/app-component-toolbox";
import { assert } from "@faro-lotv/foundation";
import { ComposeFramebuffersPass as LotvComposeFramebuffersPass } from "@faro-lotv/lotv";
import { useFrame, useThree } from "@react-three/fiber";
import { useEffect, useMemo, useRef, useState } from "react";
import { Object3D } from "three";
import { CadSubscene } from "./cad-subscene";
import {
  AnimationDuration,
  updateOpacityCadOnly,
  updateOpacityCloudOnly,
  updateOpacityOverlay,
} from "./opacity-updating";
import { RenderDispatch, dispatchRenderObjects } from "./render-dispatch";

type OverviewRenderingPipelineProps = {
  /** The sheet to render together with the dollhouse*/
  sheet: SheetObject | null;

  /** The current active point cloud */
  pointCloud: PointCloudObject | null;

  /** The current active CAD model */
  cadModel: CadModelObject | null;

  /** Whether the Cad model is selected in the scene filter options */
  cadSelected: boolean;

  /** Whether the point cloud is selected in the scene filter options */
  cloudSelected: boolean;

  /** Whether the 3d models should be rendered on demand */
  renderOnDemand: boolean;
};

/**
 * @returns The rendering pipeline to be used in overview mode.
 */
export function OverviewRenderingPipeline({
  sheet,
  pointCloud,
  cadModel,
  cadSelected,
  cloudSelected,
  renderOnDemand,
}: OverviewRenderingPipelineProps): JSX.Element {
  const scene = useThree((s) => s.scene);

  const composerRef = useRef<LotvComposeFramebuffersPass>(null);

  const [animatingCloudOpacity, setAnimatingCloudOpacity] = useState(false);

  const [animatingCadOpacity, setAnimatingCadOpacity] = useState(false);

  // Do not animate the opacity on first mount
  const isFirstMount = useRef(true);
  useEffect(() => {
    if (!isFirstMount.current) {
      setAnimatingCloudOpacity(true);
    }
  }, [pointCloud, cloudSelected]);
  useEffect(() => {
    if (!isFirstMount.current) {
      setAnimatingCadOpacity(true);
    }
  }, [cadModel, cadSelected]);
  useEffect(() => {
    isFirstMount.current = false;
  }, []);

  const animationDuration = useMemo(() => new AnimationDuration(), []);

  const transparency = useTransparencySettingsContext();

  // 'updateOpacity' is a function called each frame, that
  // sends to GPU the opacity values set in the sliders,
  // accounting also for fade-in opacity animations.
  const updateOpacity = useMemo(() => {
    const cadEnabled = !!cadModel && cadSelected;
    const cloudEnabled = !!pointCloud && cloudSelected;
    if (cadEnabled && cloudEnabled) return updateOpacityOverlay;
    else if (cadEnabled) return updateOpacityCadOnly;
    return updateOpacityCloudOnly;
  }, [cadModel, cadSelected, pointCloud, cloudSelected]);

  // Performing dispatch of objects to rendering steps in this useFrame hook
  useFrame((_, delta) => {
    dispatchRenderObjects(scene, sheet, null, cadModel, pointCloud);
    if (animatingCloudOpacity) {
      setAnimatingCloudOpacity(false);
      animationDuration.cloud = 0;
    }
    if (animatingCadOpacity) {
      setAnimatingCadOpacity(false);
      animationDuration.cad = 0;
    }
    updateOpacity({
      animationDuration,
      composerRef,
      delta,
      opacity: transparency.objectsOpacity,
    });
  }, 0);

  const storeFboRef = useRef<StoreFboPassObj>(null);

  const clipboxEvents = useClipboxEvents();
  assert(
    clipboxEvents,
    "ClipboxEventsContext should be defined in the OverviewRenderingPipeline",
  );

  const autoClipBoxFnc = useAutoClipBox(storeFboRef);

  // When the autoClipBox event is triggered, create a clipping box by sampling the depth buffer
  useTypedEvent<void>(clipboxEvents.autoClipBox, autoClipBoxFnc);

  return (
    <EffectPipeline>
      {/* Render all opaque objects first, so the potentially transparent layers can be composed on top of them later */}
      <FilteredRenderPass
        filter={(obj: Object3D) =>
          obj.userData.type === RenderDispatch.Placeholders ||
          obj.userData.type === RenderDispatch.Other
        }
        clear
        clearDepth
      />

      <ComposeFramebuffersPass ref={composerRef}>
        {/* The first subscene contains the floorplan */}
        <SubScene
          filter={(obj: Object3D) =>
            obj.userData.type === RenderDispatch.Floorplan
          }
          transparentBackground
        />
        {/** TODO: evaluate transparent background for other sub-scenes https://faro01.atlassian.net/browse/SWEB-4697 */}
        {/* The second subscene contains the LOD point cloud and is rendered on demand */}
        <PointCloudSubscene
          pointCloud={pointCloud}
          renderOnDemand={renderOnDemand}
          enabled={cloudSelected}
          filter={(obj: Object3D) =>
            obj.userData.type === RenderDispatch.LodPointCloud
          }
        />
        {/* The third subscene contains the CAD model */}
        <CadSubscene
          cadModel={cadModel}
          enabled={!!cadModel && cadSelected}
          renderOnDemand={renderOnDemand}
        />
      </ComposeFramebuffersPass>

      {/* At this point, blit the intermediate rendering result onto an offscreen FBO, to enable depth buffer picking later. */}
      <StoreFboPass ref={storeFboRef} />

      {/* Render all transparent objects in this separate pass, but do not clear the buffers.
        This allows the transparency (from anti-aliasing) to be blended correctly.*/}
      <FilteredRenderPass
        filter={(obj: Object3D) =>
          obj.userData.type === RenderDispatch.Lines ||
          obj.userData.type === RenderDispatch.OdometryPath ||
          obj.userData.type === RenderDispatch.Pivot ||
          obj.userData.type === RenderDispatch.BoxControls ||
          obj.userData.type === RenderDispatch.GridPlane
        }
        clear={false}
        clearDepth={false}
      />
      {/* The last fxaa pass improves the quality of edges and it also achieves that FilteredRenderPass is not
        the last pass, and therefore will not write itself to screen directly, but it will blend correctly onto the
        read FBO. */}
      <FxaaPass />
    </EffectPipeline>
  );
}
