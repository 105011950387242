import { useAnnotationAssignees } from "@/hooks/use-annotation-assignees";
import { useAppSelector } from "@/store/store-hooks";
import {
  AttachmentIcon,
  FaroChipList,
  FaroChipStatus,
  FaroChipTag,
  FaroIconButton,
  FaroMenuSeparator,
  FaroText,
  neutral,
  StatusOptions,
  Tag,
  ThreeDotsIcon,
  TruncatedFaroText,
  UserAvatar,
} from "@faro-lotv/flat-ui";
import { includes } from "@faro-lotv/foundation";
import {
  IElementMarkup,
  isIElementAttachment,
  isIElementMarkupState,
} from "@faro-lotv/ielement-types";
import {
  selectChildDepthFirst,
  selectChildrenDepthFirst,
} from "@faro-lotv/project-source";
import { Stack } from "@mui/system";
import { isEqual } from "lodash";
import { useMemo } from "react";

export type AnnotationListCardProps = {
  /** Annotation to render the card for */
  markup: IElementMarkup;
};

// TODO: Make responsive with proper UI (https://faro01.atlassian.net/browse/SWEB-5344)
/** @returns a single responsive card to interact with an annotation in the list */
export function AnnotationListCard({
  markup,
}: AnnotationListCardProps): JSX.Element {
  const attachments = useAppSelector(
    selectChildrenDepthFirst(markup, isIElementAttachment),
    isEqual,
  );
  const stateElement = useAppSelector(
    selectChildDepthFirst(markup, isIElementMarkupState),
  );
  const tags: Tag[] | undefined = useMemo(
    () => markup.labels?.map((l) => ({ id: l.id, label: l.name })),
    [markup.labels],
  );
  const state = includes(Object.values(StatusOptions), stateElement?.value)
    ? stateElement.value
    : StatusOptions.unclassified;
  const mainAssignee = useAnnotationAssignees(markup).at(0);

  return (
    <Stack gap={0.5}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <TruncatedFaroText variant="heading12">{markup.name}</TruncatedFaroText>
        <FaroIconButton size="xs">
          <ThreeDotsIcon />
        </FaroIconButton>
      </Stack>
      <Stack direction="row">
        <FaroChipStatus status={state} />
        {tags && (
          <FaroChipList
            size="small"
            sx={{ flexGrow: 1 }}
            chips={tags.map((t) => (
              <FaroChipTag key={t.id} label={t.label} color={t.color} dark />
            ))}
            dark
          />
        )}
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <CardAttachmentIcon count={attachments.length} />
        <UserAvatar size="xs" userDisplayInfo={mainAssignee} />
      </Stack>
      <FaroMenuSeparator />
    </Stack>
  );
}

type CardAttachmentIconProps = {
  /** the number of attachments for this annotation */
  count: number;
};

function CardAttachmentIcon({ count }: CardAttachmentIconProps): JSX.Element {
  if (count === 0) {
    // Return a div to fill the parent layout spot if there are no attachments
    return <div />;
  }
  return (
    <Stack direction="row" alignItems="center">
      <AttachmentIcon sx={{ color: neutral[800] }} />
      <FaroText variant="bodyM">{count}</FaroText>
    </Stack>
  );
}
