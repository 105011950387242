import { openAlignmentWizard } from "@/modes/alignment-wizard/open-alignment-wizard";
import {
  selectActiveCadId,
  selectCadAlignmentErrorMessage,
} from "@/store/cad/cad-slice";
import { changeMode } from "@/store/mode-slice";
import {
  setSheetForCadAlignment,
  setSheetToCadAlignmentStep,
  SheetToCadAlignmentStep,
} from "@/store/modes/sheet-to-cad-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectHasWritePermission } from "@/store/user-selectors";
import {
  IElementSection,
  isIElementGenericImgSheet,
} from "@faro-lotv/ielement-types";
import { selectChildDepthFirst } from "@faro-lotv/project-source";
import { useCallback } from "react";
import { ProjectTreeActionButton } from "./project-tree-action-button";
import {
  compatibilityMessage,
  useDisableCaptureTreeAlignment,
} from "./tree/cad-model-tree/use-disable-capture-tree-alignment";

export type AlignAreaToCadButtonProps = {
  /** The area to align */
  area: IElementSection;

  /** Tooltip to use for the button */
  tooltip: string;

  /** True to disable this action */
  disabled?: boolean;

  /**
   * This component, `AlignAreaButton`, is used in both the CaptureTree and 3D Model tree structures.
   *
   * In the 3D Model tree, the AlignmentWizard is not needed because the alignment process doesn't
   * currently support moving 3D models, which are assumed to be properly aligned. Support for moving
   * 3D models may be added in the future.
   * The AlignmentWizard is currently used for aligning only point clouds and sheets.
   *
   * The `allowUseAlignmentWizard` flag controls whether the AlignmentWizard is used. Once CAD models
   * can be aligned, this flag will no longer be needed.
   */
  allowUseAlignmentWizard?: boolean;
};

/**
 * @returns A button to start the 'align area' workflow, suitable as a context menu action for the capture/model tree
 */
export function AlignAreaButton({
  area,
  tooltip,
  disabled,
  allowUseAlignmentWizard = false,
}: AlignAreaToCadButtonProps): JSX.Element | null {
  const dispatch = useAppDispatch();

  // If the user has permission to edit the project or not
  const hasWritePermission = useAppSelector(selectHasWritePermission);

  const activeCad = useAppSelector(selectActiveCadId);

  // Currently, there is no way to select a specific sheet in an area using the UI
  // So, if the area have multiple sheets, the first one will be the one selected
  const sheet = useAppSelector(
    selectChildDepthFirst(area, isIElementGenericImgSheet),
  );
  if (!sheet) {
    throw new Error("Area does not have a sheet");
  }

  const alignClicked = useCallback(() => {
    dispatch(setSheetToCadAlignmentStep(SheetToCadAlignmentStep.setElevation));
    dispatch(setSheetForCadAlignment(sheet.id));
    dispatch(changeMode("sheetToCadAlignment"));
  }, [dispatch, sheet.id]);

  const startAlignmentWizard = useCallback(() => {
    openAlignmentWizard({ elementIdToAlign: area.id, dispatch });
  }, [area.id, dispatch]);

  const cadAlignmentErrorMessage = useAppSelector(
    selectCadAlignmentErrorMessage,
  );

  const disableAlignment = useDisableCaptureTreeAlignment();

  if (
    !activeCad ||
    cadAlignmentErrorMessage !== undefined ||
    !hasWritePermission
  ) {
    // If there is an error message associated with cad model, then model is not available.
    // Do not show align button
    return null;
  }

  return (
    <ProjectTreeActionButton
      name="Align"
      tooltip={disableAlignment ? compatibilityMessage : tooltip}
      onClick={allowUseAlignmentWizard ? startAlignmentWizard : alignClicked}
      disabled={disableAlignment || disabled}
    />
  );
}
