import { FaroChipToggle } from "@faro-lotv/flat-ui";
import { Stack } from "@mui/system";

export type FilterMenuRowProps = {
  /** true if the filter menu row should be visible */
  isVisible: boolean;
};

/** @returns the row of filter menus for the annotation list */
export function FilterMenuRow({ isVisible }: FilterMenuRowProps): JSX.Element {
  return (
    <Stack
      direction="row"
      sx={{
        my: 1,
        height: isVisible ? "30px" : 0,
        transition: "height 0.25s ease-in",
        transitionBehavior: "allow-discrete",
        overflow: "clip",
      }}
    >
      {/** TODO: Replace with proper chips to handle the filter menus (https://faro01.atlassian.net/browse/SWEB-5474)  */}
      <FaroChipToggle label="User" onClick={() => {}} />
      <FaroChipToggle label="Area" onClick={() => {}} />
    </Stack>
  );
}
